import { clsx } from 'clsx';

import { Container, CtaButton } from '@/components/features/lp';
import { TextLink } from '@/components/ui';
import { ROUTE } from '@/constants/route';

export const MainVisual = () => {
  return (
    <div
      className={clsx(
        'tw-w-full tw-h-full lg:tw-h-[470px]',
        'tw-bg-mainvisualSp lg:tw-bg-mainvisual tw-bg-no-repeat tw-bg-lp-bgYellow tw-bg-cover',
        'tw-relative',
        'tw-pt-[39px]'
      )}
    >
      <div className={clsx('tw-relative tw-z-[1]')}>
        <Container>
          <div
            className={clsx(
              'tw-flex tw-flex-col lg:tw-flex-row lg:tw-justify-between'
            )}
          >
            <div className="lg:tw-max-w-[504px] lg:tw-mt-[46px]">
              <h1
                className={clsx(
                  'tw-text-center lg:tw-text-left',
                  'tw-text-[36px] lg:tw-text-4xl',
                  'tw-text-lp-darkGreen tw-font-bold !tw-leading-[1.5]'
                )}
              >
                募集から審査まで
                <br />
                ワンストップで
                <br className="tw-block md:tw-hidden" />
                オンライン完結
              </h1>

              <div
                className={clsx(
                  'tw-mt-6',
                  'tw-max-w-[80%] md:tw-max-w-[336px] tw-mx-auto lg:tw-ml-0'
                )}
              >
                <CtaButton href={ROUTE.signup()}>
                  まずは無料で利用開始
                </CtaButton>
              </div>

              <div
                className={clsx(
                  'tw-mt-6',
                  'tw-text-center lg:tw-text-left tw-font-bold'
                )}
              >
                <TextLink
                  color={'lp.green.400'}
                  textDecoration="underline"
                  href={ROUTE.atumaruLogin()}
                >
                  あつま〜るをご利用の方はこちら
                </TextLink>
              </div>
            </div>

            <div
              className={clsx(
                'tw-max-w-[600px]',
                'tw-mt-6 md:tw-mt-0 tw-mx-auto'
              )}
            >
              <img
                className="tw-w-full"
                src="./images/lp/key-visual.png"
                alt=""
              />
            </div>
          </div>
        </Container>
      </div>
      <div
        className={clsx(
          'tw-w-full',
          'tw-absolute tw-bottom-0 tw-left-0',
          'child:tw-w-full md:child:tw-w-auto child:tw-h-auto md:child:tw-max-h-[86px]'
        )}
        role="presentation"
      >
        <svg
          viewBox="0 0 1440 86"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M1440 86C1200 86 1200 0 960 0C720 0 720 86 480 86C240 86 240 0 0 0V86H1440Z"
            fill="white"
          />
        </svg>
      </div>
    </div>
  );
};
