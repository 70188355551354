import type { PlanRow } from './type';

import { SCREENING_METHOD } from '@/constants/features/screening';

// NODE: 機能別でプランごとにデータを用意する
/**
 * アカウント
 */
const accountOrganization: PlanRow = {
  title: '主催者情報登録',
  plan: {
    light: {
      type: 'availability',
      value: 1,
    },
    standard: {
      type: 'availability',
      value: 1,
    },
    premiumScreening: {
      type: 'availability',
      value: 1,
    },
    premiumMypage: {
      type: 'availability',
      value: 1,
    },
    premiumFee: {
      type: 'availability',
      value: 1,
    },
    enterprise: {
      type: 'availability',
      value: 1,
    },
  },
};

const accountPermissionSetting: PlanRow = {
  title: 'IDごとの権限設定',
  plan: {
    light: {
      type: 'availability',
      value: 3,
    },
    standard: {
      type: 'availability',
      value: 3,
    },
    premiumScreening: {
      type: 'availability',
      value: 3,
    },
    premiumMypage: {
      type: 'availability',
      value: 3,
    },
    premiumFee: {
      type: 'availability',
      value: 3,
    },
    enterprise: {
      type: 'availability',
      value: 3,
    },
  },
};
export const AccountSection = [accountOrganization, accountPermissionSetting];

/**
 * 募集・告知
 */
const createPage: PlanRow = {
  title: '募集ページ作成・編集',
  plan: {
    light: {
      type: 'availability',
      value: 1,
    },
    standard: {
      type: 'availability',
      value: 1,
    },
    premiumScreening: {
      type: 'availability',
      value: 1,
    },
    premiumMypage: {
      type: 'availability',
      value: 1,
    },
    premiumFee: {
      type: 'availability',
      value: 1,
    },
    enterprise: {
      type: 'availability',
      value: 1,
    },
  },
};

const multiContest: PlanRow = {
  title: '複数コンテストの同時開催',
  plan: {
    light: {
      type: 'availability',
      value: 1,
    },
    standard: {
      type: 'availability',
      value: 1,
    },
    premiumScreening: {
      type: 'availability',
      value: 1,
    },
    premiumMypage: {
      type: 'availability',
      value: 1,
    },
    premiumFee: {
      type: 'availability',
      value: 1,
    },
    enterprise: {
      type: 'availability',
      value: 1,
    },
  },
};

const customDesign: PlanRow = {
  title: 'デザインカスタマイズ',
  plan: {
    light: {
      type: 'availability',
      value: 3,
    },
    standard: {
      type: 'availability',
      value: 3,
    },
    premiumScreening: {
      type: 'availability',
      value: 3,
    },
    premiumMypage: {
      type: 'availability',
      value: 3,
    },
    premiumFee: {
      type: 'availability',
      value: 3,
    },
    enterprise: {
      type: 'detail',
      value: '特設サイト制作',
    },
  },
};

const attachRefferenceFile: PlanRow = {
  title: '参考ファイル添付',
  plan: {
    light: {
      type: 'detail',
      value: '2個まで',
    },
    standard: {
      type: 'detail',
      value: '10個まで',
    },
    premiumScreening: {
      type: 'detail',
      value: '10個まで',
    },
    premiumMypage: {
      type: 'detail',
      value: '10個まで',
    },
    premiumFee: {
      type: 'detail',
      value: '10個まで',
    },
    enterprise: {
      type: 'detail',
      value: '制限なし',
    },
  },
};

const multiLanguage: PlanRow = {
  title: '複数言語対応',
  plan: {
    light: {
      type: 'availability',
      value: 3,
    },
    standard: {
      type: 'availability',
      value: 3,
    },
    premiumScreening: {
      type: 'availability',
      value: 3,
    },
    premiumMypage: {
      type: 'availability',
      value: 3,
    },
    premiumFee: {
      type: 'availability',
      value: 3,
    },
    enterprise: {
      type: 'availability',
      value: 1,
    },
  },
};

export const recruitmentSection = [
  createPage,
  multiContest,
  customDesign,
  attachRefferenceFile,
  multiLanguage,
];

/**
 * 応募管理
 */
const createForm: PlanRow = {
  title: '応募フォーム作成',
  plan: {
    light: {
      type: 'availability',
      value: 2,
    },
    standard: {
      type: 'availability',
      value: 1,
    },
    premiumScreening: {
      type: 'availability',
      value: 1,
    },
    premiumMypage: {
      type: 'availability',
      value: 1,
    },
    premiumFee: {
      type: 'availability',
      value: 1,
    },
    enterprise: {
      type: 'availability',
      value: 1,
    },
  },
};

const entryType: PlanRow = {
  title: '応募形式',
  plan: {
    light: {
      type: 'detail',
      value: 'テキスト/ファイル/リンク',
      colSpan: 5,
    },
    standard: {
      type: 'detail',
      value: 'テキスト/ファイル/リンク',
    },
    premiumScreening: {
      type: 'detail',
      value: 'テキスト/ファイル/リンク',
    },
    premiumMypage: {
      type: 'detail',
      value: 'テキスト/ファイル/リンク',
    },
    premiumFee: {
      type: 'detail',
      value: 'テキスト/ファイル/リンク',
    },
    enterprise: {
      type: 'detail',
      value: 'テキスト/ファイル/リンク',
    },
  },
};

const workSize: PlanRow = {
  title: '作品ファイル容量',
  plan: {
    light: {
      type: 'detail',
      value: '3MBまで',
    },
    standard: {
      type: 'detail',
      value: '10MBまで',
    },
    premiumScreening: {
      type: 'detail',
      value: '10MBまで',
    },
    premiumMypage: {
      type: 'detail',
      value: '10MBまで',
    },
    premiumFee: {
      type: 'detail',
      value: '10MBまで',
    },
    enterprise: {
      type: 'detail',
      value: '制限なし',
    },
  },
};

const totalFileSize: PlanRow = {
  title: 'ファイル全体容量',
  plan: {
    light: {
      type: 'detail',
      value: '500MBまで',
    },
    standard: {
      type: 'detail',
      value: '10GBまで',
    },
    premiumScreening: {
      type: 'detail',
      value: '20GBまで',
    },
    premiumMypage: {
      type: 'detail',
      value: '10GBまで',
    },
    premiumFee: {
      type: 'detail',
      value: '20GBまで',
    },
    enterprise: {
      type: 'detail',
      value: '制限なし',
    },
  },
};

const fileRetentionPeriod: PlanRow = {
  title: 'ファイル保存期間',
  plan: {
    light: {
      type: 'detail',
      value: '1年間',
      colSpan: 5,
    },
    standard: {
      type: 'detail',
      value: '1年間',
    },
    premiumScreening: {
      type: 'detail',
      value: '1年間',
    },
    premiumMypage: {
      type: 'detail',
      value: '1年間',
    },
    premiumFee: {
      type: 'detail',
      value: '1年間',
    },
    enterprise: {
      type: 'detail',
      value: '1年間',
    },
  },
};

const autoSendingReceiptMail: PlanRow = {
  title: '応募受付メールの自動送信',
  plan: {
    light: {
      type: 'availability',
      value: 1,
    },
    standard: {
      type: 'availability',
      value: 1,
    },
    premiumScreening: {
      type: 'availability',
      value: 1,
    },
    premiumMypage: {
      type: 'availability',
      value: 1,
    },
    premiumFee: {
      type: 'availability',
      value: 1,
    },
    enterprise: {
      type: 'availability',
      value: 1,
    },
  },
};

const customReceiptMail: PlanRow = {
  title: '応募受付メールの文面変更',
  plan: {
    light: {
      type: 'availability',
      value: 1,
    },
    standard: {
      type: 'availability',
      value: 1,
    },
    premiumScreening: {
      type: 'availability',
      value: 1,
    },
    premiumMypage: {
      type: 'availability',
      value: 1,
    },
    premiumFee: {
      type: 'availability',
      value: 1,
    },
    enterprise: {
      type: 'availability',
      value: 1,
    },
  },
};

const sendIndividualEmail: PlanRow = {
  title: '応募者へ個別メール送信',
  plan: {
    light: {
      type: 'availability',
      value: 3,
    },
    standard: {
      type: 'availability',
      value: 3,
    },
    premiumScreening: {
      type: 'availability',
      value: 3,
    },
    premiumMypage: {
      type: 'availability',
      value: 3,
    },
    premiumFee: {
      type: 'availability',
      value: 3,
    },
    enterprise: {
      type: 'availability',
      value: 1,
    },
  },
};

const searchWork: PlanRow = {
  title: '応募データの閲覧・検索',
  plan: {
    light: {
      type: 'availability',
      value: 1,
    },
    standard: {
      type: 'availability',
      value: 1,
    },
    premiumScreening: {
      type: 'availability',
      value: 1,
    },
    premiumMypage: {
      type: 'availability',
      value: 1,
    },
    premiumFee: {
      type: 'availability',
      value: 1,
    },
    enterprise: {
      type: 'availability',
      value: 1,
    },
  },
};

const workDownload: PlanRow = {
  title: '作品個別ダウンロード',
  plan: {
    light: {
      type: 'availability',
      value: 1,
    },
    standard: {
      type: 'availability',
      value: 1,
    },
    premiumScreening: {
      type: 'availability',
      value: 1,
    },
    premiumMypage: {
      type: 'availability',
      value: 1,
    },
    premiumFee: {
      type: 'availability',
      value: 1,
    },
    enterprise: {
      type: 'availability',
      value: 1,
    },
  },
};

const workBatchDownload: PlanRow = {
  title: '作品情報一括ダウンロード',
  plan: {
    light: {
      type: 'availability',
      value: 3,
    },
    standard: {
      type: 'availability',
      value: 1,
    },
    premiumScreening: {
      type: 'availability',
      value: 1,
    },
    premiumMypage: {
      type: 'availability',
      value: 1,
    },
    premiumFee: {
      type: 'availability',
      value: 1,
    },
    enterprise: {
      type: 'availability',
      value: 1,
    },
  },
};

const snsSummary: PlanRow = {
  title: 'SNS応募サマリー',
  plan: {
    light: {
      type: 'availability',
      value: 3,
    },
    standard: {
      type: 'availability',
      value: 3,
    },
    premiumScreening: {
      type: 'availability',
      value: 3,
    },
    premiumMypage: {
      type: 'availability',
      value: 3,
    },
    premiumFee: {
      type: 'availability',
      value: 3,
    },
    enterprise: {
      type: 'availability',
      value: 1,
    },
  },
};

const groupApplicant: PlanRow = {
  title: '団体応募対応',
  plan: {
    light: {
      type: 'availability',
      value: 3,
    },
    standard: {
      type: 'availability',
      value: 3,
    },
    premiumScreening: {
      type: 'availability',
      value: 3,
    },
    premiumMypage: {
      type: 'availability',
      value: 3,
    },
    premiumFee: {
      type: 'availability',
      value: 3,
    },
    enterprise: {
      type: 'availability',
      value: 1,
    },
  },
};

const deletePersonalData: PlanRow = {
  title: '個人情報削除',
  plan: {
    light: {
      type: 'detail',
      value: '1年で自動削除',
    },
    standard: {
      type: 'detail',
      value: '1年で自動削除/手動削除',
    },
    premiumScreening: {
      type: 'detail',
      value: '1年で自動削除/手動削除',
    },
    premiumMypage: {
      type: 'detail',
      value: '1年で自動削除/手動削除',
    },
    premiumFee: {
      type: 'detail',
      value: '1年で自動削除/手動削除',
    },
    enterprise: {
      type: 'detail',
      value: '1年で自動削除/手動削除',
    },
  },
};

const changeOfLink: PlanRow = {
  title: '応募完了後のリンク変更',
  plan: {
    light: {
      type: 'availability',
      value: 3,
    },
    standard: {
      type: 'availability',
      value: 1,
    },
    premiumScreening: {
      type: 'availability',
      value: 1,
    },
    premiumMypage: {
      type: 'availability',
      value: 1,
    },
    premiumFee: {
      type: 'availability',
      value: 1,
    },
    enterprise: {
      type: 'availability',
      value: 1,
    },
  },
};

export const ApplicationManagementSection = [
  createForm,
  entryType,
  workSize,
  totalFileSize,
  fileRetentionPeriod,
  autoSendingReceiptMail,
  customReceiptMail,
  sendIndividualEmail,
  searchWork,
  workDownload,
  workBatchDownload,
  snsSummary,
  groupApplicant,
  deletePersonalData,
  changeOfLink,
];

/**
 * 審査
 */
//
const type: PlanRow = {
  title: SCREENING_METHOD,
  plan: {
    light: {
      type: 'availability',
      value: 3,
    },
    standard: {
      type: 'detail',
      value: '採点',
    },
    premiumScreening: {
      type: 'detail',
      value: '採点/投票',
    },
    premiumMypage: {
      type: 'detail',
      value: '採点',
    },
    premiumFee: {
      type: 'detail',
      value: '採点',
    },
    enterprise: {
      type: 'detail',
      value: 'カスタマイズ',
    },
  },
};

const maxJudge: PlanRow = {
  title: '審査員の登録数',
  plan: {
    light: {
      type: 'availability',
      value: 3,
    },
    standard: {
      type: 'detail',
      value: '1人',
    },
    premiumScreening: {
      type: 'detail',
      value: '10人',
    },
    premiumMypage: {
      type: 'detail',
      value: '1人',
    },
    premiumFee: {
      type: 'detail',
      value: '1人',
    },
    enterprise: {
      type: 'detail',
      value: '制限なし',
    },
  },
};

const multiStageScreening: PlanRow = {
  title: '多段階審査（一次、二次など）',
  plan: {
    light: {
      type: 'availability',
      value: 3,
    },
    standard: {
      type: 'availability',
      value: 3,
    },
    premiumScreening: {
      type: 'availability',
      value: 1,
    },
    premiumMypage: {
      type: 'availability',
      value: 3,
    },
    premiumFee: {
      type: 'availability',
      value: 3,
    },
    enterprise: {
      type: 'availability',
      value: 1,
    },
  },
};

const assignWorks: PlanRow = {
  title: '審査員別ファイヤーウォール',
  plan: {
    light: {
      type: 'availability',
      value: 3,
    },
    standard: {
      type: 'availability',
      value: 3,
    },
    premiumScreening: {
      type: 'availability',
      value: 1,
    },
    premiumMypage: {
      type: 'availability',
      value: 3,
    },
    premiumFee: {
      type: 'availability',
      value: 3,
    },
    enterprise: {
      type: 'availability',
      value: 1,
    },
  },
};

const viewResult: PlanRow = {
  title: '審査結果の閲覧',
  plan: {
    light: {
      type: 'availability',
      value: 3,
    },
    standard: {
      type: 'availability',
      value: 1,
    },
    premiumScreening: {
      type: 'availability',
      value: 1,
    },
    premiumMypage: {
      type: 'availability',
      value: 1,
    },
    premiumFee: {
      type: 'availability',
      value: 1,
    },
    enterprise: {
      type: 'availability',
      value: 1,
    },
  },
};

const createResultPage: PlanRow = {
  title: '結果発表ページの作成',
  plan: {
    light: {
      type: 'availability',
      value: 3,
    },
    standard: {
      type: 'availability',
      value: 3,
    },
    premiumScreening: {
      type: 'availability',
      value: 3,
    },
    premiumMypage: {
      type: 'availability',
      value: 3,
    },
    premiumFee: {
      type: 'availability',
      value: 3,
    },
    enterprise: {
      type: 'availability',
      value: 1,
    },
  },
};

const workPublishSetting: PlanRow = {
  title: '応募作品の公開設定',
  plan: {
    light: {
      type: 'availability',
      value: 3,
    },
    standard: {
      type: 'availability',
      value: 3,
    },
    premiumScreening: {
      type: 'availability',
      value: 3,
    },
    premiumMypage: {
      type: 'availability',
      value: 3,
    },
    premiumFee: {
      type: 'availability',
      value: 3,
    },
    enterprise: {
      type: 'availability',
      value: 1,
    },
  },
};

const popul: PlanRow = {
  title: '一般投票',
  plan: {
    light: {
      type: 'availability',
      value: 3,
    },
    standard: {
      type: 'availability',
      value: 3,
    },
    premiumScreening: {
      type: 'availability',
      value: 3,
    },
    premiumMypage: {
      type: 'availability',
      value: 3,
    },
    premiumFee: {
      type: 'availability',
      value: 3,
    },
    enterprise: {
      type: 'availability',
      value: 1,
    },
  },
};

const ranking: PlanRow = {
  title: 'ランキング表示',
  plan: {
    light: {
      type: 'availability',
      value: 3,
    },
    standard: {
      type: 'availability',
      value: 3,
    },
    premiumScreening: {
      type: 'availability',
      value: 3,
    },
    premiumMypage: {
      type: 'availability',
      value: 3,
    },
    premiumFee: {
      type: 'availability',
      value: 3,
    },
    enterprise: {
      type: 'availability',
      value: 1,
    },
  },
};

const reviseApplicant: PlanRow = {
  title: '応募内容の修正',
  plan: {
    light: {
      type: 'availability',
      value: 3,
    },
    standard: {
      type: 'availability',
      value: 3,
    },
    premiumScreening: {
      type: 'availability',
      value: 3,
    },
    premiumMypage: {
      type: 'availability',
      value: 3,
    },
    premiumFee: {
      type: 'availability',
      value: 3,
    },
    enterprise: {
      type: 'availability',
      value: 1,
    },
  },
};

export const ScreeningSection = [
  type,
  maxJudge,
  multiStageScreening,
  assignWorks,
  viewResult,
  createResultPage,
  workPublishSetting,
  popul,
  ranking,
  reviseApplicant,
];

const editWorkByApplicant: PlanRow = {
  title: '応募者側での作品修正',
  plan: {
    light: {
      type: 'availability',
      value: 3,
    },
    standard: {
      type: 'availability',
      value: 3,
    },
    premiumScreening: {
      type: 'availability',
      value: 3,
    },
    premiumMypage: {
      type: 'availability',
      value: 1,
    },
    premiumFee: {
      type: 'availability',
      value: 1,
    },
    enterprise: {
      type: 'availability',
      value: 1,
    },
  },
};
export const MypageSection = [editWorkByApplicant];

/**
 * 出品料徴収
 */
const feePayment: PlanRow = {
  title: '決済機能',
  plan: {
    light: {
      type: 'availability',
      value: 3,
    },
    standard: {
      type: 'availability',
      value: 3,
    },
    premiumScreening: {
      type: 'availability',
      value: 3,
    },
    premiumMypage: {
      type: 'availability',
      value: 3,
    },
    premiumFee: {
      type: 'availability',
      value: 1,
    },
    enterprise: {
      type: 'availability',
      value: 1,
    },
  },
};

const feePaymentManagement: PlanRow = {
  title: '入金管理',
  plan: {
    light: {
      type: 'availability',
      value: 3,
    },
    standard: {
      type: 'availability',
      value: 3,
    },
    premiumScreening: {
      type: 'availability',
      value: 3,
    },
    premiumMypage: {
      type: 'availability',
      value: 3,
    },
    premiumFee: {
      type: 'availability',
      value: 1,
    },
    enterprise: {
      type: 'availability',
      value: 1,
    },
  },
};

export const FeeSection = [feePayment, feePaymentManagement];

/**
 * その他
 */
const otherDashboard: PlanRow = {
  title: 'ダッシュボード',
  plan: {
    light: {
      type: 'availability',
      value: 3,
    },
    standard: {
      type: 'availability',
      value: 3,
    },
    premiumScreening: {
      type: 'availability',
      value: 3,
    },
    premiumMypage: {
      type: 'availability',
      value: 3,
    },
    premiumFee: {
      type: 'availability',
      value: 3,
    },
    enterprise: {
      type: 'availability',
      value: 1,
    },
  },
};

const otherAnalytics: PlanRow = {
  title: 'アクセス解析',
  plan: {
    light: {
      type: 'availability',
      value: 1,
    },
    standard: {
      type: 'availability',
      value: 1,
    },
    premiumScreening: {
      type: 'availability',
      value: 1,
    },
    premiumMypage: {
      type: 'availability',
      value: 1,
    },
    premiumFee: {
      type: 'availability',
      value: 1,
    },
    enterprise: {
      type: 'availability',
      value: 1,
    },
  },
};

const otherDomain: PlanRow = {
  title: '独自ドメイン',
  plan: {
    light: {
      type: 'availability',
      value: 3,
    },
    standard: {
      type: 'availability',
      value: 3,
    },
    premiumScreening: {
      type: 'availability',
      value: 3,
    },
    premiumMypage: {
      type: 'availability',
      value: 3,
    },
    premiumFee: {
      type: 'availability',
      value: 3,
    },
    enterprise: {
      type: 'availability',
      value: 1,
    },
  },
};

export const OtherSection = [otherDashboard, otherAnalytics, otherDomain];
