import { Icon } from '@chakra-ui/react';

import type { IconProps } from '@chakra-ui/react';

export const IconPlay = (props: IconProps) => {
  return (
    <Icon viewBox="0 0 50 50" {...props}>
      <circle cx="25" cy="25" r="25" fill="#D9D9D9" />
      <path d="M38 24.5L17.75 36.1913L17.75 12.8087L38 24.5Z" fill="white" />
    </Icon>
  );
};
