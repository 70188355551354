import { useRouter } from 'next/router';
import { useEffect } from 'react';

import type { AnchorLinkProps } from './type';
import type { UrlObject } from 'url';

export const AnchorLink = ({
  children,
  to,
  anchor,
  onClick,
  contentRef,
}: AnchorLinkProps) => {
  // const ref = useRef<HTMLAnchorElement>(null);
  const router = useRouter();

  useEffect(() => {
    const hashChanged = (url: string) => {
      const hash = url.split('#')[1];
      if (hash !== anchor) return;
      if (!contentRef || !contentRef.current) return;

      contentRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
    };

    router.events.on('hashChangeStart', hashChanged);
    return () => router.events.off('hashChangeStart', hashChanged);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [anchor]);

  const clickHandler = (to: UrlObject, onClick?: () => void) => {
    onClick && onClick();

    if (contentRef && contentRef.current) {
      contentRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
    } else {
      router.push(to);
    }
  };

  return (
    <>
      <div
        className="tw-font-bold tw-text-base lg:tw-text-sm tw-cursor-pointer"
        onClick={() => clickHandler(to, onClick)}
      >
        {children}
      </div>
    </>
  );
};
