import { clsx } from 'clsx';

import type { CtaAreaProps } from './type';

import { CtaButton } from '@/components/features/lp/cta/CtaButton';
import { TextLink } from '@/components/ui';
import { ROUTE } from '@/constants/route';

import s from './style.module.css';

export const CtaSignup = ({}: CtaAreaProps) => {
  return (
    <div
      className={clsx(
        'tw-bg-lp-bgYellow tw-pt-10 md:tw-pt-12 tw-pb-12 md:tw-pb-16 tw-overflow-hidden',
        'tw-relative'
      )}
    >
      <div className="tw-relative tw-z-[1]">
        <div className="tw-max-w-[80%] md:tw-max-w-[336px] tw-mx-auto">
          <div
            className={clsx(
              'tw-max-w-[264px] tw-mx-auto tw-py-[6px] -tw-mb-2',
              'tw-border-[3px] tw-border-lp-green-400 tw-rounded-[50px] tw-bg-white',
              'tw-text-center tw-text-lp-green-400 tw-font-semibold tw-text-base',
              'tw-relative',
              s.balloon
            )}
          >
            メールアドレスだけで簡単登録
          </div>
          {/* TODO: GTMでイベントを仕込みやすいようにクラスを振れるようにする */}
          <CtaButton href={ROUTE.signup()}>まずは無料で利用開始</CtaButton>
        </div>

        <div className="tw-mt-[30px]">
          <p className="tw-text-center tw-text-lg tw-font-bold">
            <span className="tw-mr-[2px]">お問い合わせは</span>
            <TextLink
              color={'lp.green.400'}
              textDecoration="underline"
              href={ROUTE.contactCorporate()}
              isExternal
            >
              こちら
            </TextLink>
          </p>
        </div>
      </div>

      {/* 内側 */}
      <div
        role="presentation"
        className={clsx(
          'tw-w-[246px] md:tw-w-[560px] tw-h-[246px] md:tw-h-[560px]',
          'tw-absolute tw-top-[50%] tw-left-[50%]',
          '-tw-ml-[123px] md:-tw-ml-[280px] -tw-mt-[123px] md:-tw-mt-[280px]',
          'tw-bg-[rgba(255,255,255,0.4)] tw-rounded-full tw-shadow-[0,0,64px,rgba(240,247,196,1)]'
        )}
      />
      {/* 外側 */}
      <div
        role="presentation"
        className={clsx(
          'tw-w-[343px] md:tw-w-[780px] tw-h-[343px] md:tw-h-[780px]',
          'tw-absolute tw-top-[50%] tw-left-[50%]',
          '-tw-ml-[171.5px] md:-tw-ml-[390px] -tw-mt-[171.5px] md:-tw-mt-[390px]',
          'tw-bg-[rgba(255,255,255,0.3)] tw-rounded-full tw-shadow-[0,0,64px,rgba(240,246,196,1)]'
        )}
      />
    </div>
  );
};
