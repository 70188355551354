import { clsx } from 'clsx';

import type { availability } from '@/components/features/lp/PlanDetailTable/type';
import type { FC, ReactNode } from 'react';

import { generateAvailabilityCell } from '@/components/features/lp/PlanDetailTable/util';

import { GroupNames, PlanAndPrice, PlanName } from './const';
import {
  AccountSection,
  ApplicationManagementSection,
  FeeSection,
  MypageSection,
  OtherSection,
  recruitmentSection,
  ScreeningSection,
} from './data';

export const PlanDetailTable = () => {
  return (
    <div className="tw-relative">
      <table
        className={clsx(
          'tw-absolute tw-top-0 tw-left-4 md:tw-left-0 tw-z-docked',
          'tw-table-fixed'
        )}
      >
        <tbody>
          <tr>
            <th
              className={clsx(
                'tw-p-0',
                'tw-text-xs md:tw-text-sm tw-font-bold',
                'child:tw-flex child:tw-w-[130px] md:child:tw-w-[220px] child:tw-h-10 child:tw-items-center child:tw-justify-center'
              )}
            >
              <span
                className={clsx(
                  'tw-bg-lp-darkGreen',
                  'tw-text-xs tw-text-white tw-whitespace-pre-wrap'
                )}
              >
                プラン名
              </span>
              <span
                className={clsx(' tw-bg-lp-bgYellow', 'tw-text-lp-darkGreen')}
              >
                料金
              </span>
            </th>
          </tr>
          {/* アカウント */}
          <tr className={clsx('tw-bg-[rgba(236,244,228,1)]')}>
            <FunctionGroupCell>{GroupNames.account}</FunctionGroupCell>
          </tr>
          {AccountSection.map((row, index) => (
            <tr
              className={clsx('tw-bg-lp-bgGreen')}
              key={`account_label_${index}`}
            >
              <FunctionCell>{row.title}</FunctionCell>
            </tr>
          ))}

          {/* 募集・告知 */}
          <tr className={clsx('tw-bg-[rgba(236,244,228,1)]')}>
            <FunctionGroupCell>{GroupNames.recruitment}</FunctionGroupCell>
          </tr>
          {recruitmentSection.map((row, index) => (
            <tr
              className={clsx('tw-bg-lp-bgGreen')}
              key={`recruitment_label_${index}`}
            >
              <FunctionCell>{row.title}</FunctionCell>
            </tr>
          ))}

          {/* 応募管理 */}
          <tr className={clsx('tw-bg-[rgba(236,244,228,1)]')}>
            <FunctionGroupCell>
              {GroupNames.applicationManagement}
            </FunctionGroupCell>
          </tr>
          {ApplicationManagementSection.map((row, index) => (
            <tr
              className={clsx('tw-bg-lp-bgGreen')}
              key={`applicationManagement_label_${index}`}
            >
              <FunctionCell>{row.title}</FunctionCell>
            </tr>
          ))}

          {/* 審査 */}
          <tr className={clsx('tw-bg-[rgba(236,244,228,1)]')}>
            <FunctionGroupCell>{GroupNames.screening}</FunctionGroupCell>
          </tr>
          {ScreeningSection.map((row, index) => (
            <tr
              className={clsx('tw-bg-lp-bgGreen')}
              key={`screening_label_${index}`}
            >
              <FunctionCell>{row.title}</FunctionCell>
            </tr>
          ))}

          {/* マイページ */}
          <tr className={clsx('tw-bg-[rgba(236,244,228,1)]')}>
            <FunctionGroupCell>{GroupNames.mypage}</FunctionGroupCell>
          </tr>
          {MypageSection.map((row, index) => (
            <tr
              className={clsx('tw-bg-lp-bgGreen')}
              key={`mypage_label_${index}`}
            >
              <FunctionCell>{row.title}</FunctionCell>
            </tr>
          ))}

          {/* 出品料徴収 */}
          <tr className={clsx('tw-bg-[rgba(236,244,228,1)]')}>
            <FunctionGroupCell>{GroupNames.entryfee}</FunctionGroupCell>
          </tr>
          {FeeSection.map((row, index) => (
            <tr
              className={clsx('tw-bg-lp-bgGreen')}
              key={`entryFee_label_${index}`}
            >
              <FunctionCell>{row.title}</FunctionCell>
            </tr>
          ))}

          {/* その他 */}
          <tr className={clsx('tw-bg-[rgba(236,244,228,1)]')}>
            <FunctionGroupCell>{GroupNames.other}</FunctionGroupCell>
          </tr>
          {OtherSection.map((row, index) => (
            <tr
              className={clsx('tw-bg-lp-bgGreen')}
              key={`entryFee_label_${index}`}
            >
              <FunctionCell>{row.title}</FunctionCell>
            </tr>
          ))}
        </tbody>
      </table>

      <div
        className={clsx(
          'tw-w-[90%] md:tw-w-full tw-mx-auto',
          'tw-overflow-x-auto'
        )}
      >
        {/* プラン別の機能詳細 */}
        <table className="tw-table-fixed">
          <thead>
            <tr>
              {/* NOTE: 左カラムの幅を確保するため同じものを追加 */}
              <th
                className={clsx(
                  'tw-p-0',
                  'tw-text-sm tw-font-bold',
                  'child:tw-flex child:tw-w-[130px] md:child:tw-w-[220px] child:tw-h-10 child:tw-items-center child:tw-justify-center'
                )}
              >
                <span
                  className={clsx(
                    'tw-bg-lp-darkGreen',
                    'tw-text-xs tw-text-white tw-whitespace-pre-wrap'
                  )}
                >
                  プラン名
                </span>
                <span
                  className={clsx(' tw-bg-lp-bgYellow', 'tw-text-lp-darkGreen')}
                >
                  料金
                </span>
              </th>

              {Object.entries(PlanAndPrice).map(([plan, price], index) => (
                <PlanPriceTableHeader
                  planName={PlanName[plan as keyof typeof PlanAndPrice]}
                  price={price}
                  key={index}
                />
              ))}
            </tr>
          </thead>

          <tbody>
            {/* アカウント */}
            <GroupTableRow />
            {AccountSection.map((row, index) => {
              let accountColSpan = 0;
              return (
                <tr key={`account_cell_${index}`}>
                  <FunctionCell>{row.title}</FunctionCell>

                  {Object.values(row.plan).map((cell, cellIndex) => {
                    const key = `account_cell_${index}_${cellIndex}`;
                    if (accountColSpan !== 0) return;
                    if (cell.colSpan) {
                      accountColSpan = cell.colSpan;
                    }

                    if (cell.type === 'detail') {
                      if (accountColSpan) {
                        --accountColSpan;
                      }
                      return (
                        <TextCell key={key} colspan={cell.colSpan}>
                          {cell.value}
                        </TextCell>
                      );
                    }

                    return (
                      <AvailabilityCell key={key}>
                        {generateAvailabilityCell(cell.value as availability)}
                      </AvailabilityCell>
                    );
                  })}
                </tr>
              );
            })}

            {/* 募集・告知 */}
            <GroupTableRow />
            {recruitmentSection.map((row, index) => {
              let recruitmentColSpan = 0;
              return (
                <tr key={`recruitment_cell_${index}`}>
                  <FunctionCell>{row.title}</FunctionCell>

                  {Object.values(row.plan).map((cell, cellIndex) => {
                    const key = `recruitment_cell_${index}_${cellIndex}`;
                    if (recruitmentColSpan !== 0) return;
                    if (cell.colSpan) {
                      recruitmentColSpan = cell.colSpan;
                    }

                    if (cell.type === 'detail') {
                      if (recruitmentColSpan) {
                        --recruitmentColSpan;
                      }
                      return (
                        <TextCell key={key} colspan={cell.colSpan}>
                          {cell.value}
                        </TextCell>
                      );
                    }

                    return (
                      <AvailabilityCell key={key}>
                        {generateAvailabilityCell(cell.value as availability)}
                      </AvailabilityCell>
                    );
                  })}
                </tr>
              );
            })}

            {/* 応募管理 */}
            <GroupTableRow />
            {ApplicationManagementSection.map((row, index) => {
              let amColSpan = 0;
              return (
                <tr key={`applicationManagement_cell_${index}`}>
                  <FunctionCell>{row.title}</FunctionCell>

                  {Object.values(row.plan).map((cell, cellIndex) => {
                    const key = `applicationManagement_cell_${index}_${cellIndex}`;
                    if (amColSpan !== 0) return;
                    if (cell.colSpan) {
                      amColSpan = cell.colSpan;
                    }

                    if (cell.type === 'detail') {
                      if (amColSpan) {
                        --amColSpan;
                      }
                      return (
                        <TextCell key={key} colspan={cell.colSpan}>
                          {cell.value}
                        </TextCell>
                      );
                    }

                    return (
                      <AvailabilityCell key={key}>
                        {/* HACK: as回避 */}
                        {generateAvailabilityCell(cell.value as availability)}
                      </AvailabilityCell>
                    );
                  })}
                </tr>
              );
            })}

            {/* 審査 */}
            <GroupTableRow />
            {ScreeningSection.map((row, index) => {
              let screeningColSpan = 0;
              return (
                <tr key={`screening_cell_${index}`}>
                  <FunctionCell>{row.title}</FunctionCell>

                  {Object.values(row.plan).map((cell, cellIndex) => {
                    const key = `screening_cell_${index}_${cellIndex}`;
                    if (screeningColSpan !== 0) return;
                    if (cell.colSpan) {
                      screeningColSpan = cell.colSpan;
                    }

                    if (cell.type === 'detail') {
                      if (screeningColSpan) {
                        --screeningColSpan;
                      }
                      return (
                        <TextCell key={key} colspan={cell.colSpan}>
                          {cell.value}
                        </TextCell>
                      );
                    }

                    return (
                      <AvailabilityCell key={key}>
                        {/* HACK: as回避 */}
                        {generateAvailabilityCell(cell.value as availability)}
                      </AvailabilityCell>
                    );
                  })}
                </tr>
              );
            })}

            {/* 出品料徴収 */}
            <GroupTableRow />
            {MypageSection.map((row, index) => {
              let feeColSpan = 0;
              return (
                <tr key={`fee_cell_${index}`}>
                  <FunctionCell>{row.title}</FunctionCell>

                  {Object.values(row.plan).map((cell, cellIndex) => {
                    const key = `fee_cell_${index}_${cellIndex}`;
                    if (feeColSpan !== 0) return;
                    if (cell.colSpan) {
                      feeColSpan = cell.colSpan;
                    }

                    if (cell.type === 'detail') {
                      if (feeColSpan) {
                        --feeColSpan;
                      }
                      return (
                        <TextCell key={key} colspan={cell.colSpan}>
                          {cell.value}
                        </TextCell>
                      );
                    }

                    return (
                      <AvailabilityCell key={key}>
                        {/* HACK: as回避 */}
                        {generateAvailabilityCell(cell.value as availability)}
                      </AvailabilityCell>
                    );
                  })}
                </tr>
              );
            })}

            {/* 出品料徴収 */}
            <GroupTableRow />
            {FeeSection.map((row, index) => {
              let feeColSpan = 0;
              return (
                <tr key={`fee_cell_${index}`}>
                  <FunctionCell>{row.title}</FunctionCell>

                  {Object.values(row.plan).map((cell, cellIndex) => {
                    const key = `fee_cell_${index}_${cellIndex}`;
                    if (feeColSpan !== 0) return;
                    if (cell.colSpan) {
                      feeColSpan = cell.colSpan;
                    }

                    if (cell.type === 'detail') {
                      if (feeColSpan) {
                        --feeColSpan;
                      }
                      return (
                        <TextCell key={key} colspan={cell.colSpan}>
                          {cell.value}
                        </TextCell>
                      );
                    }

                    return (
                      <AvailabilityCell key={key}>
                        {/* HACK: as回避 */}
                        {generateAvailabilityCell(cell.value as availability)}
                      </AvailabilityCell>
                    );
                  })}
                </tr>
              );
            })}

            {/* その他 */}
            <GroupTableRow />
            {OtherSection.map((row, index) => {
              let otherColSpan = 0;
              return (
                <tr key={`other_cell_${index}`}>
                  <FunctionCell>{row.title}</FunctionCell>

                  {Object.values(row.plan).map((cell, cellIndex) => {
                    const key = `other_cell_${index}_${cellIndex}`;
                    if (otherColSpan !== 0) return;
                    if (cell.colSpan) {
                      otherColSpan = cell.colSpan;
                    }

                    if (cell.type === 'detail') {
                      if (otherColSpan) {
                        --otherColSpan;
                      }
                      return (
                        <TextCell key={key} colspan={cell.colSpan}>
                          {cell.value}
                        </TextCell>
                      );
                    }

                    return (
                      <AvailabilityCell key={key}>
                        {/* HACK: as回避 */}
                        {generateAvailabilityCell(cell.value as availability)}
                      </AvailabilityCell>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

const PlanPriceTableHeader: FC<{ planName: string; price: ReactNode }> = (
  props
) => (
  <th
    className={clsx(
      'tw-p-0',
      'tw-text-xs md:tw-text-sm tw-font-bold tw-leading-[21px]',
      'child:tw-flex child:tw-items-center child:tw-justify-center',
      'child:tw-w-[130px] md:child:tw-w-[160px] child:tw-h-10'
    )}
  >
    <span
      className={clsx(
        'tw-bg-lp-darkGreen',
        'tw-text-xs tw-text-white tw-whitespace-pre-wrap'
      )}
    >
      {props.planName}
    </span>
    <span
      className={clsx(
        'tw-bg-lp-bgYellow',
        'tw-text-xs md:tw-text-sm  tw-text-lp-darkGreen'
      )}
    >
      {props.price}
    </span>
  </th>
);

const GroupTableRow = () => {
  const columnCount = Object.keys(PlanName).length + 1;

  return (
    <tr>
      <td
        className={clsx(
          'tw-h-10 tw-bg-[rgba(236,244,228,1)]',
          'tw-border-t-[1px] tw-border-t-[rgba(0,0,0,0.1)]'
        )}
        role="presentation"
        colSpan={columnCount}
      />
    </tr>
  );
};

const FunctionGroupCell: FC<{ children: ReactNode }> = (props) => (
  <td
    className={clsx(
      'tw-w-[130px] md:tw-w-[220px] tw-px-4 tw-py-[9px] md:tw-pt-[9px] md:tw-pb-[10px]',
      'tw-bg-[rgba(236,244,228,1)]',
      'tw-border-t-[1px] tw-border-t-[rgba(0,0,0,0.1)]',
      'tw-text-left tw-text-xs md:tw-text-sm tw-font-bold tw-leading-[21px]'
    )}
  >
    {props.children}
  </td>
);

const FunctionCell: FC<{ children: ReactNode; className?: string }> = (
  props
) => (
  <td
    className={clsx(
      'tw-w-[130px] md:tw-w-[220px] tw-px-4 tw-pt-[9px] tw-pb-[10px]',
      'tw-bg-[rgba(247,253,241,1)]',
      'tw-border-t-[1px] tw-border-t-[rgba(0,0,0,0.1)]',
      'tw-text-xs md:tw-text-sm tw-font-bold tw-leading-[21px] tw-whitespace-pre-wrap',
      props.className
    )}
  >
    {props.children}
  </td>
);

const AvailabilityCell: FC<{ children: ReactNode }> = (props): JSX.Element => (
  <td
    className={clsx(
      'md:tw-h-[40px] tw-p-0 child:tw-mx-auto',
      'tw-border-t-[1px] tw-border-t-[rgba(0,0,0,0.1)]'
    )}
  >
    {props.children}
  </td>
);

const TextCell: FC<{ children: ReactNode; colspan?: number }> = (
  props
): JSX.Element => (
  <td
    className={clsx(
      'md:tw-h-[40px]',
      'tw-border-t-[1px] tw-border-t-[rgba(0,0,0,0.1)]',
      'tw-text-center tw-text-[11px]'
    )}
    colSpan={props.colspan}
  >
    {props.children}
  </td>
);
