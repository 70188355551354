import { clsx } from 'clsx';

import { featureList } from './const';

export const FeatureList = () => {
  return (
    <ul className="tw-flex tw-flex-wrap tw-gap-x-6 md:tw-gap-x-[56px] tw-gap-y-8 md:tw-gap-y-16">
      {featureList.map((item, index) => (
        <li key={index} className={clsx('tw-w-[46%] lg:tw-w-[29%]')}>
          <div
            className={clsx(
              'tw-w-[88px] md:tw-w-[216px] tw-mx-auto child:tw-mx-auto'
            )}
          >
            <img src={item.image} alt="" loading="lazy" />
          </div>

          <p
            className={clsx(
              'tw-mt-3 md:tw-mt-4',
              'tw-text-center tw-font-bold tw-text-xs md:tw-text-xl tw-leading-[1.6] tw-whitespace-pre-wrap'
            )}
          >
            {item.title}
          </p>
          <p
            className={clsx(
              'tw-mt-2 md:tw-mt-4',
              'tw-text-center tw-text-xs md:tw-text-base tw-leading-5 md:tw-leading-6'
            )}
          >
            {item.description}
          </p>
        </li>
      ))}
    </ul>
  );
};
