import { clsx } from 'clsx';

import type { LpSpGlobalNavProps } from './type';

import { AnchorLink } from '@/components/features/lp/LpGlobalNav/AnchorLink';
import { ButtonLink } from '@/components/ui';
import { ROUTE } from '@/constants/route';

import style from './style.module.css';

export const LpSpGlobalNav = ({
  navs,
  isNavOpen,
  toggle,
}: LpSpGlobalNavProps) => {
  return (
    <>
      <div className="">
        <div
          className={clsx(
            'tw:tw-block lg:tw-hidden tw-w-full',
            'tw-absolute tw-left-0 tw-top-0 tw-z-[3]',
            'tw-overflow-hidden',
            isNavOpen ? 'tw-h-auto' : 'tw-h-0',
            style.dropdown,
            isNavOpen && style.isOpen
          )}
        >
          <div className={clsx('tw-w-full tw-p-4', 'tw-bg-white ')}>
            <nav className={clsx('tw-mt-3')}>
              <ul className={clsx('tw-flex tw-flex-col tw-gap-y-6')}>
                {navs.map((nav, index) => (
                  <li key={index}>
                    {nav.isExternal ? (
                      <a
                        className="tw-font-bold"
                        href={nav.href}
                        target="_blank"
                        rel="noreferrer"
                      >
                        {nav.label}
                      </a>
                    ) : (
                      <AnchorLink
                        to={{ pathname: nav.href, hash: nav.hash }}
                        anchor={nav.hash}
                        contentRef={nav.ref}
                        onClick={toggle}
                      >
                        {nav.label}
                      </AnchorLink>
                    )}
                  </li>
                ))}
              </ul>
            </nav>

            <div className="tw-mt-9 tw-flex tw-flex-col tw-gap-y-4">
              <ButtonLink
                href={ROUTE.signup()}
                theme="cta"
                isBlock={true}
                prefetch={false}
              >
                新規無料登録
              </ButtonLink>
              <ButtonLink
                href={ROUTE.login()}
                theme="outline"
                isBlock={true}
                prefetch={false}
              >
                ログイン
              </ButtonLink>
            </div>
          </div>
        </div>
        {/* overlay */}
        <div
          className={clsx(
            isNavOpen ? 'tw-block' : 'tw-hidden',
            'tw-w-full tw-h-screen',
            'tw-absolute tw-left-0 tw-top-[56px] tw-z-[2]',
            'tw-bg-[rgba(0,0,0,0.6)]'
          )}
          role={'presentation'}
        />
      </div>
    </>
  );
};
