import type { LpGlobalNavProps } from './type';

import { LpButtonLink } from '@/components/features/lp';
import { ButtonLink } from '@/components/ui';
import { ROUTE } from '@/constants/route';

import { AnchorLink } from './AnchorLink';

export const LpGlobalNav = ({ navs }: LpGlobalNavProps) => {
  return (
    <div>
      <div className="tw-hidden lg:tw-flex tw-items-center tw-gap-x-8">
        <nav>
          <ul className="tw-flex tw-items-center tw-gap-8">
            {navs.map((nav, index) => (
              <li key={index}>
                {nav.isExternal ? (
                  <a
                    className="tw-text-bae lg:tw-text-sm tw-font-bold"
                    href={nav.href}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {nav.label}
                  </a>
                ) : (
                  <AnchorLink
                    to={{ pathname: nav.href, hash: nav.hash }}
                    anchor={nav.hash}
                    contentRef={nav.ref}
                  >
                    {nav.label}
                  </AnchorLink>
                )}
              </li>
            ))}
          </ul>
        </nav>

        <div className="tw-block md:tw-flex tw-gap-x-2">
          <ButtonLink
            href={ROUTE.login()}
            theme="outline"
            isBlock={false}
            prefetch={false}
          >
            ログイン
          </ButtonLink>
          <LpButtonLink href={ROUTE.signup()}>新規無料登録</LpButtonLink>
        </div>
      </div>
    </div>
  );
};
