import { Tabs, useTab } from '@chakra-ui/react';
import { clsx } from 'clsx';

import type { UsingImageTabsProps } from './type';
import type { TabProps } from '@chakra-ui/react';

import { LpTag } from '@/components/features/lp';

import { TABS } from './const';

export const UsingImageTabs = ({ onChange }: UsingImageTabsProps) => {
  return (
    <Tabs className="tw-w-full" onChange={onChange}>
      <div
        className={clsx(
          'tw-grid tw-grid-cols-2 md:tw-grid-cols-3 lg:tw-grid-cols-5',
          'tw-gap-2 md:tw-gap-x-3'
        )}
      >
        {TABS.map((tab, index) => (
          <div className="" key={index}>
            <CustomTab>
              <span className="tw-flex tw-items-center tw-justify-center tw-gap-1">
                <span className="tw-text-xs md:tw-text-base">{tab.label}</span>
                {tab.isPaid && <LpTag color="red">有料</LpTag>}
              </span>
            </CustomTab>
          </div>
        ))}
      </div>
    </Tabs>
  );
};

const CustomTab = (props: TabProps) => {
  const tabProps = useTab(props);
  const isSelected = !!tabProps['aria-selected'];

  return (
    <div
      {...tabProps}
      className={clsx(
        'tw-font-bold tw-flex tw-items-center tw-justify-center tw-min-h-[36px] md:tw-min-h-[48px] tw-rounded-[30px]',
        isSelected
          ? 'tw-bg-lp-darkGreen tw-text-white tw-cursor-default'
          : 'tw-bg-white tw-text-lp-darkGreen tw-border tw-border-[rgba(66,95,88,0.5)] tw-transition hover:tw-bg-[rgba(0,0,0,0.04)] tw-cursor-pointer'
      )}
    >
      {tabProps.children}
    </div>
  );
};
