import { clsx } from 'clsx';

import type { LegendItemProps } from './type';

import { IconBat, IconRound, IconTriangle } from '@/components/icons/lp';

import { themes } from './const';

export const PlanSummaryTableLegend = () => {
  return (
    <>
      <div className="tw-flex tw-justify-center tw-gap-3">
        <LegendItem Icon={<IconRound />} color="blue" label="利用可能" />
        <LegendItem Icon={<IconTriangle />} color="green" label="制限あり" />
        <LegendItem Icon={<IconBat />} color="red" label="利用不可" />
      </div>
    </>
  );
};

const LegendItem = ({ Icon, color, label }: LegendItemProps) => {
  return (
    <div
      className={clsx(
        'tw-flex tw-justify-center tw-items-center tw-gap-x-2',
        'tw-rounded tw-py-2 tw-px-4',
        themes[color]
      )}
    >
      {Icon}
      <div>{label}</div>
    </div>
  );
};
