import { clsx } from 'clsx';

import { PlanSummary } from './const';

export const PlanSummaryTable = () => {
  return (
    <div className="tw-flex">
      {/* プランのテーブル */}
      <div className={clsx('tw-overflow-x-auto')}>
        <table className={clsx('tw-table-fixed tw-w-[752px] md:tw-w-[1128px]')}>
          <thead>
            <tr
              className={clsx(
                ' tw-bg-lp-darkGreen',
                'tw-text-center tw-text-xs md:tw-text-base tw-text-white',
                'tw-border-spacing-0'
              )}
            >
              <th
                className={clsx(
                  'tw-w-[96px] md:tw-w-[220px]',
                  'tw-pt-[8px] md:tw-pt-[7px] tw-pb-[8px] md:tw-pb-[9px]'
                )}
              >
                プラン名
              </th>
              <th
                className={clsx(
                  'tw-w-[136px] md:tw-w-[220px]',
                  'tw-border-l-[1px] tw-border-l-lp-bgYellow'
                )}
              >
                料金
              </th>
              <th className="tw-border-l-[1px] tw-border-l-lp-bgYellow">
                概要
              </th>
            </tr>
          </thead>
          <tbody>
            {Object.values(PlanSummary).map((plan, index) => (
              <tr
                key={`plan_${index}`}
                className={clsx(
                  'tw-text-lp-darkGreen tw-border-b-[1px] tw-border-b-lp-[rgba(66,95,88,0.3)]'
                )}
              >
                <td
                  className={clsx(
                    'md:tw-h-[72px] tw-py-1 tw-px-2 md:tw-px-0',
                    ' tw-bg-lp-bgGreen tw-align-middle',
                    'tw-text-center tw-text-xs md:tw-text-base tw-font-bold',
                    'md:tw-whitespace-pre'
                  )}
                >
                  {plan.name}
                </td>
                <td
                  className={clsx(
                    'md:tw-h-[72px] tw-py-1',
                    ' tw-bg-lp-bgYellow',
                    'tw-text-center tw-text-xs md:tw-text-base tw-font-bold'
                  )}
                >
                  {plan.price}
                </td>
                <td
                  className={clsx(
                    'md:tw-h-[72px] tw-py-1 tw-px-4',
                    'tw-text-xs md:tw-text-sm'
                  )}
                >
                  {plan.summary}
                </td>
              </tr>
            ))}
          </tbody>
        </table>

        <aside className="tw-mt-4">
          <p>
            <small>※1　募集開始から締切までの期間で算出します。</small>
          </p>
          <p>
            <small>※2　スタンダードの利用金額にプラスして発生します。</small>
          </p>
          <p>
            <small>
              ※3　出品料パッケージのご利用には、マイページパッケージのお申込が必要です
            </small>
          </p>
        </aside>
      </div>
    </div>
  );
};

// type FeatureCellPops = {
//   featureStatus: number;
// };

// const FeatureCell = ({ featureStatus }: FeatureCellPops) => {
//   const statusIcon = useMemo(() => {
//     switch (featureStatus) {
//       case 1:
//         return <IconRound />;

//       case 2:
//         return <IconTriangle />;

//       case 3:
//         return <IconBat />;

//       default:
//         throw new Error('未定義の評価です');
//     }
//   }, [featureStatus]);

//   return (
//     <td
//       className={clsx(
//         'tw-py-3 md:tw-py-[22px]',
//         'tw-bg-white tw-border-b-[1px]'
//       )}
//     >
//       <span className="tw-flex tw-justify-center">{statusIcon}</span>
//     </td>
//   );
// };
