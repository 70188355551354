import type { NavType } from './type';

import { ROUTE } from '@/constants/route';

export const NavHrefs = {
  feature: 'features',
  usingImage: 'usingImage',
  plan: 'plan',
};

export const NAVS: NavType[] = [
  {
    label: '基本機能',
    href: ROUTE.top(),
    hash: NavHrefs.feature,
    isExternal: false,
  },
  {
    label: 'ご利用のイメージ',
    href: ROUTE.top(),
    hash: NavHrefs.usingImage,
    isExternal: false,
  },
  {
    label: 'プラン比較',
    href: ROUTE.top(),
    hash: NavHrefs.plan,
    isExternal: false,
  },
  {
    label: '審査機能',
    href: ROUTE.screeningLp(),
    hash: '',
    isExternal: false,
  },
  {
    label: 'お問い合わせ',
    href: ROUTE.contactCorporate(),
    hash: '',
    isExternal: true,
  },
];
