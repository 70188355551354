import type { GroupNameTypes } from './type';
import type { Plans } from '@/components/features/lp/PlanSummaryTable/const';
import type { ReactNode } from 'react';

export const PlanName: Record<Plans, string> = {
  light: 'ライト',
  standard: 'スタンダード',
  premiumScreening: 'プレミアム\n（審査パッケージ）',
  premiumMypage: 'プレミアム\n（マイページパッケージ）',
  premiumFee: 'プレミアム\n（出品料パッケージ）',
  enterprise: 'エンタープライズ',
};

export const GroupNames: Record<GroupNameTypes, string> = {
  account: 'アカウント',
  recruitment: '募集・告知',
  applicationManagement: '応募管理',
  screening: '審査',
  entryfee: '出品料徴収',
  mypage: 'マイページ',
  other: 'その他',
};

export const PlanAndPrice: Record<keyof typeof PlanName, ReactNode> = {
  light: '無料',
  standard: (
    <>
      10万円/月<small>※1</small>
    </>
  ),
  premiumScreening: (
    <>
      30万円〜<small>※2</small>
    </>
  ),
  premiumMypage: (
    <>
      30万円〜<small>※2※3</small>
    </>
  ),
  premiumFee: (
    <span className="tw-text-xs">
      初期費用20万円＋
      <br />
      出品料の5%<small>※2</small>
    </span>
  ),
  enterprise: 'お問い合わせ',
};
