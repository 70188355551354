import { ROUTE } from '@/constants/route';

type MENU_TYPE = {
  label: string;
  href: string;
};

export const FOOTER_MENUS: MENU_TYPE[] = [
  {
    label: 'サービス一覧',
    href: 'https://koubo.co.jp/service',
  },
  {
    label: 'コンテスト成功の法則',
    href: 'https://koubo.co.jp/contest#knowledge',
  },
  {
    label: '人材募集',
    href: 'https://koubo.co.jp/recruitment',
  },
  {
    label: '会社案内',
    href: 'https://koubo.co.jp/about',
  },
  {
    label: 'Koubo',
    href: ROUTE.koubo(),
  },
  {
    label: 'あつま〜るをご利用の方',
    href: ROUTE.atumaruLogin(),
  },
];

export const SUB_MENUS: MENU_TYPE[] = [
  {
    label: 'サイトのご利用にあたって',
    href: ROUTE.sitePolicy(),
  },
  {
    label: '個人情報保護方針',
    href: 'https://koubo.jp/privacy',
  },
];
