import { clsx } from 'clsx';

import { LogoFiles } from '@/components/features/lp/LogoList/const';

export const LogoList = () => {
  return (
    <ul
      className={clsx(
        'tw-grid tw-grid-cols-2 md:tw-grid-cols-4 tw-gap-y-2 tw-gap-x-[15px] lg:tw-gap-6',
        'tw-mt-[34px] md:tw-mt-16'
      )}
    >
      {LogoFiles.map((file, i) => (
        <li className="tw-min-h-[62px] lg:tw-min-h-[100px]" key={i}>
          <img src={file.imageUrl} alt={file.alt} loading="lazy" />
        </li>
      ))}
    </ul>
  );
};
