export const TABS: tabType[] = [
  {
    index: 1,
    label: 'アカウント作成',
    isPaid: false,
  },
  {
    index: 2,
    label: '公募情報の登録と掲載',
    isPaid: false,
  },
  {
    index: 3,
    label: '応募管理',
    isPaid: false,
  },
  {
    index: 4,
    label: '出品料管理',
    isPaid: true,
  },
  {
    index: 5,
    label: '審査',
    isPaid: true,
  },
];

type tabType = {
  index: number;
  label: string;
  isPaid: boolean;
};
