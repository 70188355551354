import type { FeatureItem } from './type';

export const featureList: FeatureItem[] = [
  {
    title: '集客効果UP',
    image: './images/lp/img-new-function-1.svg',
    description:
      '業界最大250万PV/月の「Koubo」と連携。登録した募集ページが自動的に掲載・公開されます。',
  },
  {
    title: '即日WEB公開',
    image: './images/lp/img-new-function-2.svg',
    description:
      '募集の概要を登録いただければ、最短で即日公開が可能です。急遽募集を始めたい、といったご要望にもお応えします。',
  },
  {
    title: 'SNS応募に対応',
    image: './images/lp/img-new-function-3.svg',
    description:
      '各種SNSで応募された作品も自動集計します。ほかにも、作品の一般投票やランキング表示など柔軟なカスタマイズが可能です。',
  },
  {
    title: '審査はオンラインで完結',
    image: './images/lp/img-new-function-4.svg',
    description:
      '作品の印刷、郵送、集計などの手作業がすべて不要に。WEB上でY/N投票、採点形式ほかさまざまな審査方式に対応します。',
  },
  {
    title: '出品料と応募作品のマッチング',
    image: './images/lp/img-new-function-5.svg',
    description:
      '面倒な出品料の手続きもおまかせ。決済システムと連携し、自動で入金管理。事前決済なので入金漏れも防げます。',
  },
  {
    title: 'アクセス解析で次の施策を',
    image: './images/lp/img-new-function-6.svg',
    description:
      'アクセス数や応募数などを可視化して表示。問題点を分析し、応募が集まるように次の施策を実行してください。',
  },
];
