export const IconTriangle = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 2L0 22H24L12 2ZM12 7.83095L5.29857 19H18.7014L12 7.83095Z"
        fill="#78C197"
      />
    </svg>
  );
};
