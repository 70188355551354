export const LogoFiles = [
  {
    imageUrl: '/images/lp/logo-tokyo.jpg',
    alt: '東京都',
  },
  {
    imageUrl: '/images/lp/logo-moj-go.jpg',
    alt: '法務省',
  },
  {
    imageUrl: '/images/lp/logo-sports.jpg',
    alt: 'スポーツ庁',
  },
  {
    imageUrl: '/images/lp/logo-ibaraki-city.jpg',
    alt: '茨木市',
  },
  {
    imageUrl: '/images/lp/logo-makurazaki.jpg',
    alt: '鹿児島県 枕崎市',
  },
  {
    imageUrl: '/images/lp/logo-nishinakai.jpg',
    alt: '一般財団法人 二科会デザイン部',
  },
  {
    imageUrl: '/images/lp/logo-tokyo-kita.jpg',
    alt: '東京都北区',
  },
  {
    imageUrl: '/images/lp/logo-otani-univ.jpg',
    alt: '大谷大学',
  },
];
