import { clsx } from 'clsx';

import type { PublishingContestCardProps } from './type';

import { HeadlessLink } from '@/components/ui';
import { DATE_FORMAT } from '@/constants/date';
import { ROUTE } from '@/constants/route';
import { generateContestDisplayEntryPeriod } from '@/utils/features/contest';

import style from './style.module.css';

export const PublishingContestCard = ({
  contest,
}: PublishingContestCardProps) => {
  const period = generateContestDisplayEntryPeriod(
    contest.entry,
    DATE_FORMAT.yyyyMMdd
  );

  return (
    <HeadlessLink
      href={ROUTE.kouboContestDetail(contest.id)}
      className={clsx(
        'tw-h-full',
        'tw-block tw-bg-white tw-p-4',
        'tw-shadow-[0px_4px_24px_rgba(0,0,0,0.08)]',
        'tw-transition-shadow hover:tw-shadow-[0px_8px_32px_rgba(0,0,0,0.2)]'
      )}
      target="_blank"
    >
      <>
        <div
          className={clsx('tw-w-full  tw-mx-auto', style.thumbnail)}
          style={{
            backgroundImage: `url(${
              contest.thumbnail
                ? contest.thumbnail?.file?.url
                : './images/lp/img-no-image.png'
            })`,
          }}
        >
          <img
            className="tw-w-full tw-invisible"
            src={contest.thumbnail?.file?.url ?? './images/lp/img-no-image.png'}
            alt={contest.thumbnail?.file?.name}
            loading="lazy"
          />
        </div>
        <div className="tw-mt-4">
          <div
            className={clsx('tw-text-lp-darkGreen tw-text-base tw-font-bold')}
          >
            {contest.name}
          </div>
          <div className={clsx('tw-mt-2 tw-text-xs')}>{period}</div>
        </div>
      </>
    </HeadlessLink>
  );
};
