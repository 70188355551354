import { clsx } from 'clsx';

import { Container } from '@/components/features/lp';
import {
  FOOTER_MENUS,
  SUB_MENUS,
} from '@/components/features/lp/LpGlobalFooter/const';
import { IconArrow } from '@/components/icons/lp';
import { Link, TextLink } from '@/components/ui';

export const LpGlobalFooter = () => {
  return (
    <footer className="tw-bg-white tw-pt-8 tw-pb-10 md:tw-pb-14">
      <Container>
        <div className="tw-relative">
          <p className="tw-text-base tw-text-lp-darkGreen tw-font-bold">
            コンテストのことなら公募ガイド
          </p>

          <nav className="tw-mt-6 md:tw-mt-[17.5px]">
            <ul
              className={clsx(
                'tw-flex tw-flex-col lg:tw-flex-row',
                'lg:tw-divide-x-[1px] tw-divide-x-[rgba(66,95,88,0.3)]'
              )}
            >
              {FOOTER_MENUS.map((menu, index) => {
                return (
                  <li
                    className={clsx(
                      'lg:tw-px-6 lg:first:tw-pl-0',
                      'tw-pt-3 tw-pb-[15px] lg:tw-py-0',
                      'tw-border-b-[1px] tw-border-b-darkGreen lg:tw-border-b-0',
                      'tw-relative'
                    )}
                    key={`footer_menu_${index}`}
                  >
                    <TextLink
                      color={'lp.darkGreen'}
                      fontSize="sm"
                      href={menu.href}
                    >
                      {menu.label}
                    </TextLink>

                    <span
                      className={clsx(
                        'lg:tw-hidden',
                        'tw-absolute tw-right-2 tw-top-1/2 -tw-mt-3'
                      )}
                    >
                      <IconArrow />
                    </span>
                  </li>
                );
              })}
            </ul>
          </nav>

          <ul
            className={clsx(
              'tw-flex tw-flex-col lg:tw-flex-row',
              'tw-gap-y-4 lg:tw-gap-y-0 lg:tw-gap-x-8',
              'tw-mt-6 lg:tw-mt-[17.5px]'
            )}
          >
            {SUB_MENUS.map(({ label, href }, index) => {
              return (
                <li key={`sub_menu_${index}`}>
                  <TextLink color={'lp.darkGreen'} fontSize="xs" href={href}>
                    {label}
                  </TextLink>
                </li>
              );
            })}
          </ul>

          <div className="tw-w-[64px] tw-absolute lg:tw-top-0 tw-right-0 -tw-bottom-[8px] lg:tw-bottom-auto">
            <Link href="https://privacymark.jp/" target="_blank">
              <img src="./images/lp/img-privacy.png" alt="" />
            </Link>
          </div>
        </div>
      </Container>
    </footer>
  );
};
