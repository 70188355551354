import type { UsingImageItemProps } from '@/components/features/lp/UsingImageItem/type';

import { LpButtonLink } from '@/components/features/lp/LpButtonLink';
import { APP_META } from '@/constants/app';
import { SCREENING_METHOD_TYPE_NAME } from '@/constants/features/screening';
import { ROUTE } from '@/constants/route';
import { OWNER } from '@/constants/site';

type UsingImageKeys =
  | 'accounts'
  | 'page'
  | 'createForm'
  | 'entryFee'
  | 'screening';

export const USING_IMAGES: Record<UsingImageKeys, UsingImageItemProps[]> = {
  accounts: [
    {
      number: 1,
      title: 'アカウントを登録',
      thumbnail: '/images/lp/usage-account-1.jpg',
      description: `${APP_META.TITLE}は無料ではじめることができます。\n「新規無料登録」ボタンからアカウントをご登録ください。`,
      footer: (
        <LpButtonLink href={ROUTE.signup()}>新規無料登録はこちら</LpButtonLink>
      ),
    },
    {
      number: 2,
      title: '組織情報登録',
      thumbnail: '/images/lp/usage-account-2.jpg',
      description:
        'ご登録いただいたメールアドレス宛に認証メールをお送りいたします。\nメールに記載されたURLより、主催者様の組織情報をご登録ください。',
    },
  ],
  page: [
    {
      number: 1,
      title: '公募情報の登録',
      thumbnail: '/images/lp/usage-page-1.jpg',
      description:
        '主催者情報の登録完了後、募集したい公募情報をご登録ください。',
    },
    {
      number: 2,
      title: '掲載申請',
      thumbnail: '/images/lp/usage-page-2.jpg',
      description: `募集ページの公開には${OWNER.NAME}からの掲載承認が必要です。忘れずに申請しましょう。`,
    },
    {
      number: 3,
      title: '募集ページを公開',
      thumbnail: '/images/lp/usage-page-3.jpg',
      description:
        '承認後は公開ボタンひとつ押すだけで、すぐにKoubo上に公開されます。',
    },
  ],
  createForm: [
    {
      number: 1,
      title: '応募フォームを作成',
      thumbnail: '/images/lp/usage-form-1.jpg',
      description: (
        <>
          {APP_META.TITLE}で応募を受け付ける場合、応募フォームを作成ください。
          <p>
            <small className="tw-text-xs md:tw-text-base">
              ※有料プランでは出品料徴収や、各項目・受付メールのカスタマイズをすることもできます。
            </small>
          </p>
        </>
      ),
    },
    {
      number: 2,
      title: '作品を受付',
      thumbnail: '/images/lp/usage-form-2.jpg',
      description:
        '応募フォームは募集期間に合わせてWEB公開され、応募者から作品の応募を受け付けることができます。',
    },
    {
      number: 3,
      title: '応募作品を管理',
      thumbnail: '/images/lp/usage-form-3.jpg',
      description: `応募された作品と応募者情報はすべて${APP_META.TITLE}上で管理できます。`,
    },
  ],
  entryFee: [
    {
      number: 1,
      title: '業務フローに関するヒアリング',
      thumbnail: '/images/lp/usage-fee-1.jpg',
      description: `出品料の決済手段、応募作品とのマッチング方法など、現状に関する業務フローと抱えられている課題についてヒアリングさせていただき、${APP_META.TITLE}をカスタマイズさせていただきます。`,
      footer: (
        <LpButtonLink href={ROUTE.contactCorporate()} isExternal>
          お問い合わせはこちら
        </LpButtonLink>
      ),
    },
    {
      number: 2,
      title: '出品料決済用ページの開設',
      thumbnail: '/images/lp/usage-fee-2.jpg',
      description:
        '応募者の方に出品料をご決済いただくためのページを開設します。',
    },
    {
      number: 3,
      title: '応募者による出品料の決済',
      thumbnail: '/images/lp/usage-fee-3.jpg',
      description:
        '応募者の方に決済用ページから出品料をご決済いただきます。決済が完了したら、応募者には支払いIDが発行されます。',
    },
    {
      number: 4,
      title: '作品を受付',
      thumbnail: '/images/lp/usage-fee-4.jpg',
      description:
        '発行された支払いIDを併記する形で、応募者から作品を受け付けを行います。',
    },
    {
      number: 5,
      title: '応募作品と支払い状況を突合',
      thumbnail: '/images/lp/usage-fee-5.jpg',
      description:
        '応募作品データと決済状況を突合し、出品料の支払い漏れ等をチェックします。',
    },
  ],
  screening: [
    {
      number: 1,
      title: '審査を新規登録',
      thumbnail: '/images/lp/usage-screening-1.jpg',
      description: (
        <>
          実施したい審査を新規登録します。
          <p>
            <small className="tw-text-xs md:tw-text-base">
              ※審査パッケージでは{SCREENING_METHOD_TYPE_NAME.scoring}／
              {SCREENING_METHOD_TYPE_NAME.voting}
            </small>
          </p>
        </>
      ),
    },
    {
      number: 2,
      title: '審査員を登録',
      thumbnail: '/images/lp/usage-screening-2.jpg',
      description: (
        <>
          審査を行う審査員を登録します。審査員登録はメールアドレスだけで可能です。
          <p>
            <small className="tw-text-xs md:tw-text-base">
              ※審査パッケージでは審査員を10名まで登録することができ、各審査員に担当作品を割り振ることができます。
            </small>
          </p>
        </>
      ),
    },
    {
      number: 3,
      title: '審査を開始',
      thumbnail: '/images/lp/usage-screening-3.jpg',
      description:
        '行いたい審査と審査員の登録が完了したら、審査プロセスをスタートすることができます。',
    },
    {
      number: 4,
      title: '採点を実施',
      thumbnail: '/images/lp/usage-screening-4.jpg',
      description:
        '審査員はオンライン上で作品を審査します。事務局側では各審査員の採点状況を確認することができ、審査遅延、評価のレベル調整などを審査員に促すことができます。',
    },
    {
      number: 5,
      title: '多段階審査対応',
      thumbnail: '/images/lp/usage-screening-5.jpg',
      description: (
        <>
          一次審査が完了したら二次審査、その次は最終審査と、次のステップの審査を設定することができます。
          <br />
          前審査の平均点や審査員ごとの点数を比較しながら、次の審査対象作品を絞ることも可能です。
          <br />
          <small className="tw-text-xs md:tw-text-base">
            ※一次審査、二次審査などの多段階審査を設定できるのは審査パッケージのみの機能です
          </small>
          <br />
          <small className="tw-text-xs md:tw-text-base">
            ※一次審査前のネガティブチェックや、三次、四次審査などもっと細かく審査を設定することも可能です
          </small>
        </>
      ),
    },
  ],
};
