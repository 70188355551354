import type { ReactNode } from 'react';

import { PlanName } from '@/components/features/lp/PlanDetailTable/const';

export const PlanSummaryItemHeads = [
  {
    label: '募集ページ作成',
  },
  {
    label: '作品管理',
  },
  {
    label: '応募フォーム作成',
  },
  {
    label: '出品料徴収',
  },
  {
    label: '審査',
  },
  {
    label: '各種カスタマイズ',
  },
];

export type Plans =
  | 'light'
  | 'standard'
  | 'premiumScreening'
  | 'premiumMypage'
  | 'premiumFee'
  | 'enterprise';

type Summary = {
  name: ReactNode;
  price: ReactNode;
  summary: string;
};

export const PlanSummary: Record<Plans, Summary> = {
  light: {
    name: PlanName.light,
    price: '無料',
    summary:
      '告知、募集、作品管理、効果測定など、告知から作品受付までの基本機能をご利用いただけます。',
  },
  standard: {
    name: PlanName.standard,
    price: (
      <>
        10万円/月<span className="tw-text-xs">※1</span>
      </>
    ),
    summary:
      '作品募集機能に加え、応募作品の一括ダウンロード、応募作品の評価など、効率的なコンテスト運営を実現するためのプランです。',
  },
  premiumScreening: {
    name: PlanName.premiumScreening,
    price: (
      <>
        30万円〜<span className="tw-text-xs">※2</span>
      </>
    ),
    summary:
      'スタンダードプランに、より高度な審査機能を追加したプランです。Y/N評価、点数評価など様々な評価パターンへの対応、審査員別権限設定、オンライン審査など、充実した審査機能を実装したプランです。コンテストのジャンルや応募数などにより料金は変動いたします。',
  },
  premiumMypage: {
    name: PlanName.premiumMypage,
    price: (
      <>
        30万円〜<span className="tw-text-xs">※2※3</span>
      </>
    ),
    summary:
      'スタンダードプランに、マイページ機能を追加したプランです。応募者側で応募後の作品確認・修正などが可能になります。コンテストのジャンルや応募数などにより料金は変動いたします。',
  },
  premiumFee: {
    name: PlanName.premiumFee,
    price: (
      <>
        初期費用20万円＋
        <br />
        出品料の5％<span className="tw-text-xs">※2</span>
      </>
    ),
    summary:
      'スタンダードプランに、出品料徴収機能を追加したプランです。煩雑となりがちな出品料と応募作品のマッチング業務の負荷軽減を実現します。',
  },
  enterprise: {
    name: PlanName.enterprise,
    price: 'お問い合わせ',
    summary:
      '主催者様のニーズに応じ、すべての機能をカスタマイズできるプランです。オリジナルLPの制作、SNS応募のサマリー、独自ドメインなどにもご要望に合わせて対応いたします。',
  },
};
