import { Link as ChakraLink } from '@chakra-ui/react';
import { clsx } from 'clsx';
import { forwardRef } from 'react';

import type { CtaButtonProps } from './type';

import { HeadlessLink } from '@/components/ui';

export const CtaButton = forwardRef<HTMLAnchorElement, CtaButtonProps>(
  ({ href = '#', children }, ref) => {
    return (
      <ChakraLink
        as={HeadlessLink}
        ref={ref}
        href={href}
        _hover={{ textDecoration: 'none' }}
        prefetch={false}
      >
        {/* ボタンの見た目 */}
        <span
          className={clsx(
            'tw-block tw-w-full tw-bg-lp-green-400 tw-rounded-md tw-py-5 md:tw-py-[21px]',
            'hover:tw-bg-lp-green-500 tw-transition',
            'tw-shadow-[0_4px_12px_rgba(0,0,0,0.12)]'
          )}
        >
          <span className="tw-flex tw-items-center tw-justify-center tw-gap-2">
            <span
              className={clsx(
                'tw-mb-[2px]',
                'tw-text-xl md:tw-text-2xl tw-text-white tw-font-bold',
                'tw-leading-none'
              )}
            >
              {children}
            </span>
            <span>
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clipPath="url(#clip0_5077_1897)">
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24ZM8.58984 7.41L9.99984 6L15.9998 12L9.99984 18L8.58984 16.59L13.1698 12L8.58984 7.41Z"
                    fill="white"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_5077_1897">
                    <rect width="24" height="24" fill="white" />
                  </clipPath>
                </defs>
              </svg>
            </span>
          </span>
        </span>
      </ChakraLink>
    );
  }
);

CtaButton.displayName = 'CtaButton';
