import { DATE_FORMAT } from '@/constants/date';
import { CONTEST_ENTRY_ALWAYS_OPEN_NAME } from '@/constants/features/contest';
import { formatDate, setTimesToDate } from '@/utils/date';

/**
 *  実際の募集開始・終了日時の取得
 */
export const getActualEntryPeriod = ({
  date,
  withTimePicker,
  type,
}: {
  date: Date;
  withTimePicker: boolean;
  type: 'start' | 'end';
}) => {
  switch (type) {
    case 'start': {
      // input: 2022/9/2 -> output: 2022/9/2 00:00:00
      // input: 2022/9/2 12:00 -> output: 2022/9/2 12:00
      return formatDate(date, DATE_FORMAT.yyyyMMddHHmm);
    }
    case 'end': {
      if (withTimePicker) {
        // input: 2022/9/2 12:00 -> output: 2022/9/2 12:00
        return formatDate(date, DATE_FORMAT.yyyyMMddHHmm);
      } else {
        // input: 2022/9/2 -> output: 2022/9/2 23:59
        return formatDate(
          setTimesToDate(date, 23, 59, 0),
          DATE_FORMAT.yyyyMMddHHmm
        );
      }
    }
  }
};

/** 募集期間テキストの生成 */
export const generateContestDisplayEntryPeriod = (
  entry: {
    startDate?: string;
    startTime?: string;
    endDate?: string;
    endTime?: string;
  },
  dateTimeFormat = DATE_FORMAT.yyyyMMddHHmm,
  dateFormat = DATE_FORMAT.yyyyMMdd
): string => {
  const { startDate, startTime, endDate, endTime } = entry;

  if (!startDate && !endDate) return CONTEST_ENTRY_ALWAYS_OPEN_NAME;

  const startDateTime = startDate
    ? generateEntryStartDateText(
        { startDate, startTime },
        dateTimeFormat,
        dateFormat
      )
    : '';

  const endDateTime = endDate
    ? generateEntryEndDateText({ endDate, endTime }, dateTimeFormat, dateFormat)
    : '';

  return `${startDateTime} ~ ${endDateTime}`;
};

/** 募集開始日テキストの生成 */
export const generateEntryStartDateText = (
  entry: {
    startDate: string;
    startTime?: string;
  },
  dateTimeFormat = DATE_FORMAT.yyyyMMddHHmm,
  dateFormat = DATE_FORMAT.yyyyMMdd
) => {
  const { startDate, startTime } = entry;
  return startTime != null
    ? formatDate(`${startDate}T${startTime}`, dateTimeFormat)
    : formatDate(startDate, dateFormat);
};

/** 締め切り日テキストの生成 */
export const generateEntryEndDateText = (
  entry: {
    endDate: string;
    endTime?: string;
  },
  dateTimeFormat = DATE_FORMAT.yyyyMMddHHmm,
  dateFormat = DATE_FORMAT.yyyyMMdd
) => {
  const { endDate, endTime } = entry;
  return endTime != null
    ? formatDate(`${endDate}T${endTime}`, dateTimeFormat)
    : formatDate(endDate, dateFormat);
};
