import clsx from 'clsx';

import { CtaButton } from '@/components/features/lp/cta/CtaButton';
import { ROUTE } from '@/constants/route';
import {} from 'react';

export const CtaContact = () => {
  return (
    <div
      className={clsx(
        'tw-bg-lp-bgYellow tw-py-6 md:tw-pt-12 md:tw-pb-[52px] tw-overflow-hidden',
        'tw-relative'
      )}
    >
      <div className="tw-relative tw-z-[1]">
        <p
          className={clsx(
            'tw-mb-6 tw-text-center tw-text-lp-darkGreen tw-font-medium tw-text-base md:tw-text-2xl md:tw-leading-9'
          )}
        >
          審査プランのご利用をご希望される方は
          <br />
          お問い合わせください
        </p>

        <div className="tw-max-w-[80%] md:tw-max-w-[336px] tw-mx-auto">
          <CtaButton href={ROUTE.contactCorporate()}>お問い合わせ</CtaButton>
        </div>
      </div>

      {/* 内側 */}
      <div
        role="presentation"
        className={clsx(
          'tw-w-[246px] md:tw-w-[560px] tw-h-[246px] md:tw-h-[560px]',
          'tw-absolute tw-top-[50%] tw-left-[50%]',
          '-tw-ml-[123px] md:-tw-ml-[280px] -tw-mt-[123px] md:-tw-mt-[280px]',
          'tw-bg-[rgba(255,255,255,0.4)] tw-rounded-full tw-shadow-[0,0,64px,rgba(240,247,196,1)]'
        )}
      />
      {/* 外側 */}
      <div
        role="presentation"
        className={clsx(
          'tw-w-[343px] md:tw-w-[780px] tw-h-[343px] md:tw-h-[780px]',
          'tw-absolute tw-top-[50%] tw-left-[50%]',
          '-tw-ml-[171.5px] md:-tw-ml-[390px] -tw-mt-[171.5px] md:-tw-mt-[390px]',
          'tw-bg-[rgba(255,255,255,0.3)] tw-rounded-full tw-shadow-[0,0,64px,rgba(240,246,196,1)]'
        )}
      />
    </div>
  );
};
