export const IconBat = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.13109 4.01025L4.00977 6.13157L10.0202 12.142L4.00977 18.1524L6.13109 20.2737L12.1415 14.2633L18.1519 20.2737L20.2732 18.1524L14.2628 12.142L20.2732 6.13157L18.1519 4.01025L12.1415 10.0207L6.13109 4.01025Z"
        fill="#EB7144"
      />
    </svg>
  );
};
