import {} from 'react';
import { clsx } from 'clsx';

import type { CaseStudyModalProps } from './type';

import { BaseModal, Button } from '@/components/ui';

export const CaseStudyModal = ({
  isOpen,
  onClose,
  title,
  imageUrl,
  groupName,
  prize,
  link,
  qas,
}: CaseStudyModalProps) => {
  return (
    <BaseModal
      isOpen={isOpen ?? false}
      size="4xl"
      title=""
      closeOnEsc={false}
      closeOnOverlayClick={true}
      withCloseButton={false}
      onClose={() => onClose && onClose()}
    >
      <div className={clsx('tw-flex tw-flex-col tw-items-center')}>
        <header className={clsx('tw-w-full tw-text-sm tw-font-bold')}>
          <h1 className={clsx('tw-text-center')}>
            <span className="tw-text-xl md:tw-text-2xl tw-border-b-4 tw-border-b-lp-green-400">
              {title}
            </span>
          </h1>
          <p
            className={clsx(
              'tw-mt-7',
              'tw-text-center tw-font-normal tw-text-xs md:tw-text-base'
            )}
          >
            {groupName}
          </p>
          <p
            className={clsx(
              'tw-mt-[2px]',
              'tw-text-center tw-font-normal tw-text-xs md:tw-text-base'
            )}
          >
            「{prize}」
          </p>

          <p className="tw-mt-1 tw-text-center">
            <a
              className="tw-text-xs md:tw-text-sm tw-text-lp-green-400"
              href={link}
              target="_blunk"
            >
              {link}
            </a>
          </p>
        </header>

        <div
          className={clsx(
            'tw-max-w-[246px] md:tw-max-w-[520px] md:tw-min-h-[348px]',
            'tw-mt-[45px] lg:tw-mt-[69px] tw-mx-auto',
            'tw-bg-[#f3f3f5]'
          )}
        >
          <img src={imageUrl} alt="" className="tw-mx-auto" />
        </div>

        <main
          className={clsx(
            'tw-mt-[60px] md:tw-mt-[85px] tw-flex tw-flex-col tw-gap-10'
          )}
        >
          {qas.map((qa) => (
            <section key={qa.answer}>
              <h2
                className={clsx(
                  'tw-font-bold tw-text-sm md:tw-text-base',
                  'before:tw-inline-block before:tw-align-middle before:tw-content-[""] before:tw-w-[2em] before:tw-h-[1px] before:tw-bg-lp-darkGreen'
                )}
              >
                {qa.question}
              </h2>
              <p className="tw-mt-3 tw-text-sm md:tw-text-base tw-leading-[1.6]">
                {qa.answer}
              </p>
            </section>
          ))}
        </main>
        <footer className={clsx('tw-mt-6 tw-text-center tw-text-sm')}>
          <Button theme="outline" onClick={onClose}>
            閉じる
          </Button>
        </footer>
      </div>
    </BaseModal>
  );
};
