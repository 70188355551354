import { Link as ChakraLink } from '@chakra-ui/react';
import { clsx } from 'clsx';
import { forwardRef } from 'react';

import type { LpButtonLinkProps } from './type';

import { HeadlessLink } from '@/components/ui';

export const LpButtonLink = forwardRef<HTMLAnchorElement, LpButtonLinkProps>(
  ({ href = '#', isExternal, children }, ref) => {
    return (
      <ChakraLink
        as={HeadlessLink}
        href={href}
        ref={ref}
        isExternal={isExternal}
        prefetch={false}
      >
        <span
          className={clsx(
            'tw-inline-block tw-bg-lp-green-400 hover:tw-bg-lp-green-500 tw-rounded-md tw-py-1 md:tw-pt-[7px] md:tw-pb-[9px] tw-px-2 md:tw-px-4 tw-transition'
          )}
        >
          <span
            className={clsx(
              'tw-text-xs md:tw-text-base tw-text-white tw-font-bold'
            )}
          >
            {children}
          </span>
        </span>
      </ChakraLink>
    );
  }
);

LpButtonLink.displayName = 'LpButtonLink';
