export const SITE = {
  DOMAIN: process.env.NEXT_PUBLIC_APP_DOMAIN || '',
  TWITTER: {
    SITE: '@kouboguide',
    DOMAIN: '公募ガイド社',
  },
};

export const OWNER = {
  NAME: '公募ガイド社',
  CEO: '浅田邦裕',
  TEL: '03-5405-2170',
  TEL_OPEN_HOUR: '10:00～17:00',
  FAX: '03-5405-2061',
  EMAIL: 'club@koubo.co.jp',
  ZIPCODE: '〒105-0011',
  ADDRESS: '東京都港区芝公園一丁目8番21号 芝公園リッジビル5F',
};
