import { clsx } from 'clsx';
import { forwardRef } from 'react';

import type { NavigationButtonProps } from './type';

export const NavigationButton = forwardRef<
  HTMLDivElement,
  NavigationButtonProps
>(({ className }, ref) => {
  return (
    <div
      className={clsx(
        'tw-w-10 tw-h-10 tw-rounded-circle tw-cursor-pointer tw-select-none',
        'tw-bg-lp-green-400 hover:tw-bg-lp-green-500',
        'tw-transition-colors tw-ease-out',
        'tw-flex tw-items-center tw-justify-center',
        className
      )}
      ref={ref}
    >
      <span className={clsx('tw-w-2 tw-h-2 tw-border-b tw-border-white')} />
    </div>
  );
});

NavigationButton.displayName = 'NavigationButton';
