import { clsx } from 'clsx';
import { useEffect, useState } from 'react';
import { Navigation, FreeMode, Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import type { PublishingContestSliderProps } from './type';

import { NavigationButton } from './NavigationButton';

import 'swiper/css';
import 'swiper/css/pagination';

export const PublishingContestSlider = ({
  contests,
  arrowPosY,
  view,
  spView = view - 1,
  spaceBetween = 20,
  SpSpaceBetween = 10,
  className,
}: PublishingContestSliderProps) => {
  const [prevEl, setPrevEl] = useState<HTMLDivElement | null>(null);
  const [nextEl, setNextEl] = useState<HTMLDivElement | null>(null);
  const [mounted, setMounted] = useState<boolean>(false);

  useEffect(() => {
    setMounted(true);
  }, []);

  return (
    <>
      <style jsx global>{`
        .prevArrow {
          margin-left: -64px;
        }
        .prevArrow > * {
          transform: rotate(45deg);
        }

        .nextArrow {
          margin-right: -64px;
        }
        .nextArrow > * {
          transform: rotate(-45deg);
        }

        .swiper-wrapper {
          align-items: stretch !important;
          padding-bottom: 40px;
        }
        .swiper-slide {
          height: auto;
        }
      `}</style>
      {mounted && (
        <div className={clsx('tw-relative', className)}>
          <Swiper
            navigation={{
              prevEl,
              nextEl,
            }}
            pagination={{
              clickable: true,
              el: '#pagination',
              renderBullet: (index, className) => {
                return (
                  '<span class="' +
                  className +
                  ' !tw-bg-lp-darkGreen !tw-w-4 !tw-h-4 !tw-mx-2"></span>'
                );
              },
              currentClass: '!tw-opacity-100',
            }}
            autoHeight={true}
            loop={view < contests.length}
            freeMode={true}
            spaceBetween={SpSpaceBetween}
            slidesPerView={spView}
            modules={[Navigation, Pagination, FreeMode]}
            breakpoints={{
              [768]: {
                spaceBetween: spaceBetween,
                freeMode: false,
                slidesPerView: spView + 1,
                slidesPerGroup: spView + 1,
              },
              [1024]: {
                spaceBetween: spaceBetween,
                freeMode: false,
                slidesPerView: view + 1,
                slidesPerGroup: view + 1,
              },
            }}
          >
            <div className="tw-pb-2 child:tw-flex">
              {contests.map((item, index) => (
                <SwiperSlide className="tw-h-full" key={`contest_${index}`}>
                  {item}
                </SwiperSlide>
              ))}
            </div>
          </Swiper>

          {/* Navigation */}
          <div
            className={clsx(
              'tw-hidden tw-absolute tw-w-full tw-top-1/2 tw-z-docked -tw-translate-y-1/2',
              'tw-pointer-events-none child:tw-pointer-events-auto',
              view < contests.length &&
                'lg:tw-flex md:tw-justify-between md:tw-contests-center'
            )}
            style={{ marginTop: arrowPosY }}
          >
            {/* Prev */}
            <NavigationButton
              ref={setPrevEl}
              className={clsx(
                '-tw-translate-x-1/2 child:tw-border-l child:tw-translate-x-1/4 ',
                'prevArrow'
              )}
            />

            {/* Next */}
            <NavigationButton
              ref={setNextEl}
              className={clsx(
                'tw-translate-x-1/2 child:tw-border-r child:-tw-translate-x-1/4 ',
                'nextArrow'
              )}
            />
          </div>

          <div
            id="pagination"
            className={clsx(
              'tw-hidden',
              'tw-relative md:tw-flex tw-justify-center md:tw-mt-5'
            )}
          />
        </div>
      )}
    </>
  );
};
