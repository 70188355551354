import { useDisclosure } from '@chakra-ui/react';
import { clsx } from 'clsx';

import type { LpGlobalHeaderProps } from './type';

import { LpGlobalNav } from '@/components/features/lp';
import { LpSpGlobalNav } from '@/components/features/lp/LpSpGlobalNav';
import { Link } from '@/components/ui';
import { APP_META } from '@/constants/app';
import { ROUTE } from '@/constants/route';

export const LpGlobalHeader = ({ navs }: LpGlobalHeaderProps) => {
  const { isOpen, onToggle } = useDisclosure();

  return (
    <>
      <header className={clsx('tw-sticky tw-top-0 tw-z-sticky')}>
        <div
          className={clsx(
            'tw-relative tw-z-[2]',
            'tw-bg-white',
            !isOpen && 'tw-shadow-[0_4px_16px_rgba(0,0,0,0.08)]'
          )}
        >
          <div className="tw-flex tw-justify-between tw-items-center tw-py-4 tw-px-4 md:tw-px-6">
            <div>
              <Link href={ROUTE.top()}>
                <img src="/logo_h.svg" height={24} alt={APP_META.TITLE} />
              </Link>
            </div>

            <LpGlobalNav navs={navs} />

            <div className="tw:tw-block lg:tw-hidden">
              <div onClick={onToggle}>
                {isOpen ? (
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <rect
                      x="5.63672"
                      y="4.22168"
                      width="20"
                      height="2"
                      transform="rotate(45 5.63672 4.22168)"
                      fill="#0AC763"
                    />
                    <rect
                      width="20"
                      height="2"
                      transform="matrix(-0.707107 0.707107 0.707107 0.707107 18.3652 4.22168)"
                      fill="#0AC763"
                    />
                  </svg>
                ) : (
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <rect x="2" y="3" width="20" height="2" fill="#0AC763" />
                    <rect x="2" y="11" width="20" height="2" fill="#0AC763" />
                    <rect x="2" y="19" width="20" height="2" fill="#0AC763" />
                  </svg>
                )}
              </div>
            </div>
          </div>
        </div>

        <div className="tw-relative tw-z-[1]">
          <LpSpGlobalNav navs={navs} isNavOpen={isOpen} toggle={onToggle} />
        </div>
      </header>
    </>
  );
};
