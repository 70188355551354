import {} from 'react';
import { useDisclosure } from '@chakra-ui/react';
import { clsx } from 'clsx';

import type { CaseStudyCardProps } from './type';

import { CaseStudyModal } from '@/components/features/lp';

export const CaseStudyCard = ({
  title,
  imageUrl,
  groupName,
  prize,
  link,
  qas,
  className,
}: CaseStudyCardProps) => {
  const modal = useDisclosure();

  return (
    <>
      <div
        className={clsx(
          'tw-bg-white tw-p-4 tw-shadow-[0px_4px_24px_rgba(0,0,0,0.08)]',
          'tw-transition-shadow hover:tw-shadow-[0px_8px_32px_rgba(0,0,0,0.2)] tw-cursor-pointer',
          className
        )}
        onClick={modal.onOpen}
      >
        <div className="md:tw-w-[91%] md:tw-max-h-[220px] tw-object-cover tw-mx-auto">
          <img className="tw-w-full" src={imageUrl} alt="" loading="lazy" />
        </div>
        <div className="tw-mt-4">
          <p
            className={clsx(
              'tw-text-center tw-font-bold tw-text-xl tw-text-lp-darkGreen'
            )}
          >
            {groupName}
          </p>
          <p className={clsx('tw-mt-2 tw-text-center')}>{prize}</p>
        </div>
      </div>

      <CaseStudyModal
        isOpen={modal.isOpen}
        onClose={modal.onClose}
        title={title}
        groupName={groupName}
        prize={prize}
        link={link}
        imageUrl={imageUrl}
        qas={qas}
      />
    </>
  );
};
