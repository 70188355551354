import { clsx } from 'clsx';

import type { LpSectionProps } from './type';

export const LpSection = ({
  className,
  id,
  children,
  contentRef,
}: LpSectionProps) => {
  return (
    <section
      className={clsx(
        'tw-pt-12 md:tw-pt-20 tw-pb-16 md:tw-pb-[120px]',
        className
      )}
      id={id}
      ref={contentRef}
    >
      {children}
    </section>
  );
};
