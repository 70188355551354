import type { UsingImageItemProps } from './type';

export const UsingImageItem = ({
  number,
  title,
  thumbnail,
  description,
  footer,
}: UsingImageItemProps) => {
  return (
    <div className="tw-flex tw-items-start md:tw-items-center tw-gap-x-4 md:tw-gap-x-8">
      <div className="tw-w-[30%] md:tw-w-[240px] tw-h-[64px] md:tw-h-[160px]">
        <img src={thumbnail} alt="" loading="lazy" />
      </div>

      <div className="tw-w-[63%] md:tw-w-[74%]">
        <p className="tw-text-sm md:tw-text-xl tw-font-bold">
          <span className='tw-text-lp-green-400 after:tw-content-["."]'>
            {number}
          </span>
          {title}
        </p>

        <p className="tw-mt-2 tw-text-xs md:tw-text-base">{description}</p>

        {footer && <footer className="tw-mt-2">{footer}</footer>}
      </div>
    </div>
  );
};
