import { clsx } from 'clsx';

import type { ContainerProps } from './type';

export const Container = ({ children, classes = '' }: ContainerProps) => {
  return (
    <div
      className={clsx(
        'tw-w-full tw-max-w-[1128px] tw-mx-auto',
        'tw-flex tw-flex-col',
        'tw-px-4 lg:tw-px-0',
        classes
      )}
    >
      {children}
    </div>
  );
};
