import type { Colors } from './type';

export const COLORS: Record<Colors, { bg: string }> = {
  red: {
    bg: 'tw-bg-[rgba(235,113,68,1)]',
  },
  blue: {
    bg: 'tw-bg-[rgba(110,183,209,1)]',
  },
  green: {
    bg: 'tw-bg-[rgba(120,193,151,1)]',
  },
};
