import {} from 'react';
import { clsx } from 'clsx';

import type { LpTagProps } from './type';

import { COLORS } from './const';

export const LpTag = ({ children, color }: LpTagProps) => {
  return (
    <span
      className={clsx(
        'tw-py-[2px] tw-px-[3px] tw-rounded',
        'tw-text-white tw-text-[10px] tw-font-bold',
        COLORS[color].bg ?? ''
      )}
    >
      {children}
    </span>
  );
};
