import { type ContestPlans } from '@/graphql/generated';

export const ENTERPRISE_PLAN: Pick<ContestPlans, 'id' | 'name'> = {
  id: -1,
  name: 'エンタープライズ',
};

export const CONTEST_STATUS = {
  CREATED: 'created',
  APPROVAL_REQUESTED: 'approval_requested',
  UNAPPROVED: 'unapproved',
  CORRECTION_REQUIRED: 'correction_required',
  UNPUBLISHED: 'unpublished',
  PUBLICATION_SCHEDULED: 'publication_scheduled',
  PUBLISHED: 'published',
} as const;

export const CONTEST_STATUS_NAME = {
  [CONTEST_STATUS.CREATED]: '未申請',
  [CONTEST_STATUS.APPROVAL_REQUESTED]: '申請中',
  [CONTEST_STATUS.UNAPPROVED]: '非承認',
  [CONTEST_STATUS.CORRECTION_REQUIRED]: '要修正',
  [CONTEST_STATUS.UNPUBLISHED]: '非公開',
  [CONTEST_STATUS.PUBLICATION_SCHEDULED]: '公開予定',
  [CONTEST_STATUS.PUBLISHED]: '公開中',
} as const;

export const CONTEST_ENTRY_STATUS = {
  BEFORE_OPEN: 'before_open',
  OPEN: 'open',
  CLOSED: 'closed',
} as const;

export const CONTEST_ENTRY_STATUS_NAME = {
  [CONTEST_ENTRY_STATUS.BEFORE_OPEN]: '募集前',
  [CONTEST_ENTRY_STATUS.OPEN]: '募集中',
  [CONTEST_ENTRY_STATUS.CLOSED]: '募集終了',
} as const;

export const CONTEST_ENTRY_ALWAYS_OPEN_NAME = '随時募集';
