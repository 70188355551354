import { clsx } from 'clsx';
import { useState } from 'react';

import { UsingImageItem, UsingImageTabs } from '@/components/features/lp';
import { USING_IMAGES } from '@/components/features/lp/UsingImageArea/const';
import { TABS } from '@/components/features/lp/UsingImageTabs/const';

export const UsingImageArea = () => {
  const [currentStepIndex, setCurrentStepIndex] = useState(TABS[0].index);

  const handleChange = (tabIndex: number) => {
    if (tabIndex > TABS.length - 1) return;
    const stepIndex = TABS[tabIndex].index;
    setCurrentStepIndex(stepIndex);
  };

  return (
    <>
      <UsingImageTabs onChange={handleChange} />

      <div className="tw-mt-6">
        {currentStepIndex === TABS[0].index && (
          <div
            className={clsx(
              'tw-flex tw-flex-col tw-gap-y-4',
              'tw-bg-white tw-px-4 md:tw-px-7 tw-py-6 md:tw-py-8'
            )}
          >
            {USING_IMAGES.accounts.map((item, index) => (
              <UsingImageItem key={index} {...item} />
            ))}
          </div>
        )}

        {currentStepIndex === TABS[1].index && (
          <div
            className={clsx(
              'tw-flex tw-flex-col tw-gap-y-4',
              'tw-bg-white tw-px-4 md:tw-px-7 tw-py-6 md:tw-py-8'
            )}
          >
            {USING_IMAGES.page.map((item, index) => (
              <UsingImageItem key={index} {...item} />
            ))}
          </div>
        )}

        {currentStepIndex === TABS[2].index && (
          <div
            className={clsx(
              'tw-flex tw-flex-col tw-gap-y-4',
              'tw-bg-white tw-px-4 md:tw-px-7 tw-py-6 md:tw-py-8'
            )}
          >
            {USING_IMAGES.createForm.map((item, index) => (
              <UsingImageItem key={index} {...item} />
            ))}
          </div>
        )}

        {currentStepIndex === TABS[3].index && (
          <div
            className={clsx(
              'tw-flex tw-flex-col tw-gap-y-4',
              'tw-bg-white tw-px-4 md:tw-px-7 tw-py-6 md:tw-py-8'
            )}
          >
            {USING_IMAGES.entryFee.map((item, index) => (
              <UsingImageItem key={index} {...item} />
            ))}
          </div>
        )}

        {currentStepIndex === TABS[4].index && (
          <div
            className={clsx(
              'tw-flex tw-flex-col tw-gap-y-4',
              'tw-bg-white tw-px-4 md:tw-px-7 tw-py-6 md:tw-py-8'
            )}
          >
            {USING_IMAGES.screening.map((item, index) => (
              <UsingImageItem key={index} {...item} />
            ))}
          </div>
        )}
      </div>
    </>
  );
};
