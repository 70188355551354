import type { availability } from '@/components/features/lp/PlanDetailTable/type';

import { IconBat, IconRound, IconTriangle } from '@/components/icons/lp';

/**
 * 指定されたavailabilityからアイコンコンポーネントに変換する
 * @param value
 * @returns
 */
export const generateAvailabilityCell = (value: availability) => {
  switch (value) {
    case 1:
      return <IconRound />;

    case 2:
      return <IconTriangle />;

    case 3:
    default:
      return <IconBat />;
  }
};
