import {} from 'react';
import { clsx } from 'clsx';

import type { FunctionPanelProps } from './type';

export const FunctionPanel = ({
  reverse = false,
  thumbnail,
  functionNumber,
  title,
  description,
  priceTag,
}: FunctionPanelProps) => {
  return (
    <section
      className={clsx(
        'tw-flex tw-items-start lg:tw-items-center tw-justify-center lg:tw-flex-row',
        reverse
          ? 'tw-flex-col lg:tw-flex-row-reverse'
          : 'tw-flex-col lg:tw-flex-row'
      )}
    >
      <div
        className={clsx(
          'tw-max-w-[240px] md:tw-max-w-[648px]',
          'tw-rounded-[8px] tw-overflow-hidden',
          'tw-mx-auto'
        )}
      >
        <img src={thumbnail} alt="" loading="lazy" />
      </div>

      <div
        className={clsx(
          'tw-max-w-full lg:tw-max-w-[544px] tw-pt-[22px] md:tw-p-10 lg:tw-rounded-[8px]',
          'tw-relative tw-z-[1]',
          'tw-bg-white lg:tw-shadow-[0px_8px_16px_rgba(0,0,0,0.08)]',
          reverse ? 'lg:-tw-mr-[60px]' : 'lg:-tw-ml-[60px]'
        )}
      >
        <header>
          <h3
            className={clsx(
              'tw-flex tw-items-center',
              'tw-text-xl md:tw-text-[32px] tw-font-bold'
            )}
          >
            <span
              className={clsx(
                'tw-mr-3',
                'tw-text-[28px] md:tw-text-[40px] tw-text-lp-green-400'
              )}
            >
              {functionNumber.toString().padStart(2, '0')}
            </span>
            {title}
          </h3>
        </header>
        <div className="tw-mt-2 md:tw-mt-[22px]">
          <p className="tw-text-sm md:tw-text-base tw-text-lp-darkGreen tw-leading-[25px] md:tw-leading-[28.8px] tw-whitespace-pre-wrap">
            {description}
          </p>
        </div>

        <footer className={clsx('tw-mt-2 md:tw-mt-4')}>{priceTag}</footer>
      </div>
    </section>
  );
};
