import { useDisclosure } from '@chakra-ui/react';

import { PlanDetailTable } from '@/components/features/lp';
import { BaseModal, Button } from '@/components/ui';

export const PlanDetailModal = () => {
  const modal = useDisclosure();

  return (
    <>
      <div className="tw-w-[309px] tw-mx-auto">
        <Button theme="outline" size="lg" onClick={modal.onOpen}>
          プラン別の機能を詳しくみる
        </Button>
      </div>

      <BaseModal
        isOpen={modal.isOpen}
        title=""
        size="6xl"
        autoFocus={false}
        closeOnEsc={false}
        closeOnOverlayClick={true}
        withCloseButton={false}
        onClose={modal.onClose}
      >
        <>
          <PlanDetailTable />
          <footer className="tw-mt-4">
            <p>
              <small>※1　募集開始から締切までの期間で算出します。</small>
            </p>
            <p>
              <small>※2　スタンダードの利用金額にプラスして発生します。</small>
            </p>
            <p>
              <small>
                ※3　出品料パッケージのご利用には、マイページパッケージのお申込が必要です
              </small>
            </p>

            <div className="tw-mt-10 tw-flex tw-justify-center">
              <Button theme="outline" onClick={modal.onClose} isBlock={false}>
                閉じる
              </Button>
            </div>
          </footer>
        </>
      </BaseModal>
    </>
  );
};
